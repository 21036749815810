"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const snack_bar_1 = require("@angular/material/snack-bar");
const dnaR_snack_bar_component_1 = require("./dnaR-snack-bar.component");
const i0 = require("@angular/core");
const i1 = require("@angular/material/snack-bar");
/**
 * A service for display DNA-R snack-bar notifications.
 *
 * The snack-bar functionality is provide by Material snack-bar. This service provides the DNA-R look and feel.
 */
class DnaRSnackBarService {
    /**
     * Initializes a new instance of the DnaRSnackBarService service.
     *
     * @param snackBar A service for displaying Material snack-bar notifications.
     */
    constructor(snackBar) {
        this.snackBar = snackBar;
        /**
         * Opens an information snack-bar.
         *
         * @param title The title (aka Noun) to show in the snack-bar.
         * @param message The message (aka Verb) to show in the snack-bar
         */
        this.info = (title, message) => {
            this.snackBar.openFromComponent(dnaR_snack_bar_component_1.DnaRSnackBarComponent, {
                data: {
                    icon: "check-circle-solid",
                    title,
                    message,
                },
                panelClass: ["dnar-snack-bar", "dnar-snack-bar-information"]
            });
        };
        /**
         * Opens a success snack-bar.
         *
         * @param title The title (aka Noun) to show in the snack-bar.
         * @param message The message (aka Verb) to show in the snack-bar
         */
        this.success = (title, message) => {
            this.snackBar.openFromComponent(dnaR_snack_bar_component_1.DnaRSnackBarComponent, {
                data: {
                    icon: "check-circle-solid",
                    title,
                    message,
                },
                panelClass: ["dnar-snack-bar", "dnar-snack-bar-success"]
            });
        };
        /**
         * Opens a failure snack-bar.
         *
         * @param title The title (aka Noun) to show in the snack-bar.
         * @param message The message (aka Verb) to show in the snack-bar. This is optional only to accommodate some legacy references.
         */
        this.failure = (title, message = "") => {
            this.snackBar.openFromComponent(dnaR_snack_bar_component_1.DnaRSnackBarComponent, {
                data: {
                    icon: "error-fill",
                    title,
                    message,
                },
                panelClass: ["dnar-snack-bar", "dnar-snack-bar-failure"]
            });
        };
    }
}
exports.DnaRSnackBarService = DnaRSnackBarService;
DnaRSnackBarService.ɵfac = function DnaRSnackBarService_Factory(t) { return new (t || DnaRSnackBarService)(i0.ɵɵinject(i1.MatSnackBar)); };
DnaRSnackBarService.ɵprov = i0.ɵɵdefineInjectable({ token: DnaRSnackBarService, factory: DnaRSnackBarService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSnackBarService, [{
        type: core_1.Injectable
    }], function () { return [{ type: i1.MatSnackBar }]; }, null); })();
