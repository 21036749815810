"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./value-with-effective-date");
/**
 * The results of the @see ValuesWithEffectiveDateDialogComponent.
 */
class ValueWithEffectiveDateDialogResult {
    /**
     * Initializes a new instance of the ValueWithEffectiveDateDialogResult class.
     *
     * @param cancelled - true if the dialog was cancelled, otherwise false.
     * @param data - The new set of @see ValuesWithEffectiveDate containing all the changes made be the use in the dialog. null if the dialog was cancelled.
     */
    constructor(cancelled, data = null) {
        this.cancelled = cancelled;
        this.data = data;
    }
}
exports.ValueWithEffectiveDateDialogResult = ValueWithEffectiveDateDialogResult;
