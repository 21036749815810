"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const coercion_1 = require("@angular/cdk/coercion");
const core_1 = require("@angular/core");
const period_type_option_1 = require("./period-type-option");
const i0 = require("@angular/core");
const i1 = require("../../../common/components/dnaR-input/dnaR-input.component");
const i2 = require("@angular/forms");
const i3 = require("../../../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.component");
/**
 * <period></period> is a control to define a period.
 */
class PeriodComponent {
    /**
     * Initializes a new instance of the PeriodComponent class.
     */
    constructor() {
        this.dropdownSettings = {
            addAllOption: false,
            singleSelection: true,
            idField: "id",
            textField: "name",
            disableField: "disabled",
            itemsShowLimit: 1,
            maxHeight: 500,
            showClearSelectedItems: false,
        };
        this._periodType = null;
        /**
         * Event emitted when the period type has been changed by the user.
         */
        this.periodTypeChange = new core_1.EventEmitter();
        this._periodLength = null;
        /**
         * Event emitted when the period length has been changed by the user.
         */
        this.periodLengthChange = new core_1.EventEmitter();
        this._disabled = false;
        // Default peiod types to the standard list.
        this.periodTypes = period_type_option_1.getStandardPeriodTypes();
    }
    /**
     * Period type options.
     */
    get periodTypes() {
        return this._periodTypes;
    }
    set periodTypes(value) {
        if (!value) {
            this._periodTypes = [];
        }
        else {
            this._periodTypes = value;
        }
    }
    /**
     * Selected period type.
     */
    get periodType() {
        return this._periodType;
    }
    set periodType(value) {
        if (value === undefined) {
            this._periodType = null;
        }
        else {
            this._periodType = value;
        }
    }
    /**
     * Period length.
     */
    get periodLength() {
        return this._periodLength;
    }
    set periodLength(value) {
        if (value === undefined) {
            this._periodLength = null;
        }
        else {
            this._periodLength = value;
        }
    }
    /**
     * Whether the component is disabled.
     */
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = coercion_1.coerceBooleanProperty(value);
    }
    /**
     * Handles the period length model change event.
     *
     * @param newValue The new value of the period length model.
     */
    periodLengthModelChanged(newValue) {
        this.periodLength = newValue;
        this.periodLengthChange.emit(this.periodLength);
    }
    /**
     * Handles the period type model change event.
     *
     * @param newValue The new value of the period type model.
     */
    periodTypeModelChanged(newValue) {
        this.periodType = newValue;
        this.periodTypeChange.emit(this.periodType);
    }
}
exports.PeriodComponent = PeriodComponent;
PeriodComponent.ɵfac = function PeriodComponent_Factory(t) { return new (t || PeriodComponent)(); };
PeriodComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PeriodComponent, selectors: [["period"]], inputs: { periodTypes: "periodTypes", periodType: "periodType", periodLength: "periodLength", disabled: "disabled" }, outputs: { periodTypeChange: "periodTypeChange", periodLengthChange: "periodLengthChange" }, decls: 3, vars: 7, consts: [[1, "period"], ["name", "period-length", 1, "period-length", 3, "ngModel", "disabled", "placeholder", "ngModelChange"], ["name", "periodType", 1, "period-type", 3, "settings", "ngModel", "data", "disabled", "ngModelChange"]], template: function PeriodComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "dnaR-input", 1);
        i0.ɵɵlistener("ngModelChange", function PeriodComponent_Template_dnaR_input_ngModelChange_1_listener($event) { return ctx.periodLengthModelChanged($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "dnaR-dropdown-multiSelect", 2);
        i0.ɵɵlistener("ngModelChange", function PeriodComponent_Template_dnaR_dropdown_multiSelect_ngModelChange_2_listener($event) { return ctx.periodTypeModelChanged($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngModel", ctx.periodLength)("disabled", ctx.disabled)("placeholder", 0);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("settings", ctx.dropdownSettings)("ngModel", ctx.periodType)("data", ctx.periodTypes)("disabled", ctx.disabled);
    } }, directives: [i1.DnaRInput, i2.NgControlStatus, i2.NgModel, i3.DnaRDropdownMultiSelect], styles: [".period[_ngcontent-%COMP%] {\n  display: flex;\n  gap: 16px;\n}\n.period-length[_ngcontent-%COMP%] {\n  width: 49px;\n}\n.period-type[_ngcontent-%COMP%] {\n  width: 110px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PeriodComponent, [{
        type: core_1.Component,
        args: [{
                selector: "period",
                templateUrl: "./period.component.html",
                styleUrls: ["./period.component.less"],
            }]
    }], function () { return []; }, { periodTypes: [{
            type: core_1.Input
        }], periodType: [{
            type: core_1.Input
        }], periodTypeChange: [{
            type: core_1.Output
        }], periodLength: [{
            type: core_1.Input
        }], periodLengthChange: [{
            type: core_1.Output
        }], disabled: [{
            type: core_1.Input
        }] }); })();
