"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Standard DNA-R Alert types
 */
var DnaRAlertTypeEnum;
(function (DnaRAlertTypeEnum) {
    DnaRAlertTypeEnum["Information"] = "information";
    DnaRAlertTypeEnum["Success"] = "success";
    DnaRAlertTypeEnum["Danger"] = "danger";
    DnaRAlertTypeEnum["Warning"] = "warning";
})(DnaRAlertTypeEnum = exports.DnaRAlertTypeEnum || (exports.DnaRAlertTypeEnum = {}));
