"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_dmf_rate_type_enum_1 = require("../enums/ilu-dmf-rate-type.enum");
const common_1 = require("@angular/common");
const i0 = require("@angular/core");
/**
 * Returns the rate value formatted for the specified @see IluDmfRateType.
 *
 * @example
 * <span>{{rateValueAmount | rateValue: rateValuType}}</span>
 */
class RateValuePipe {
    /**
     * Initializes a new instance of the @see RateValuePipe class.
     *
     * @param local The locale code for the locale formatting rules to use.
     */
    constructor(locale) {
        this.locale = locale;
    }
    /**
     * Returns the rate value formatted for the specified @see IluDmfRateType.
     *
     * @param value The rate value.
     * @param rateType The DMF rate type.
     * @returns The rate value formatted for the specified. Return empty string if rate value or the rate type is null or undefined.
     */
    transform(rateValue, rateType) {
        // rateValue could be 0 which, and that is OK to format.
        if (rateType && !(rateValue === null || rateValue === undefined)) {
            if (rateType === ilu_dmf_rate_type_enum_1.IluDmfRateType.Dollar) {
                return `$${common_1.formatNumber(rateValue, this.locale, "1.2-4")}`;
            }
            if (rateType === ilu_dmf_rate_type_enum_1.IluDmfRateType.Percentage) {
                return `${common_1.formatNumber(rateValue, this.locale, "1.0-4")}%`;
            }
            return "";
        }
        return "";
    }
}
exports.RateValuePipe = RateValuePipe;
RateValuePipe.ɵfac = function RateValuePipe_Factory(t) { return new (t || RateValuePipe)(i0.ɵɵdirectiveInject(core_1.LOCALE_ID)); };
RateValuePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "rateValue", type: RateValuePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RateValuePipe, [{
        type: core_1.Pipe,
        args: [{ name: "rateValue" }]
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: [core_1.LOCALE_ID]
            }] }]; }, null); })();
