"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_dmf_rate_period_enum_1 = require("../enums/ilu-dmf-rate-period.enum");
const i0 = require("@angular/core");
/**
 * Returns the display text for the specified @see IluDmfRatePeriod.
 *
 * @example
 * <span>{{ratePeriod | iluDmfRatePeriodText}}</span>
 */
class IluDmfRatePeriodTextPipe {
    /**
     * Returns the display text for the specified @see IluDmfRatePeriod.
     *
     * @param value The DMF rate period on value.
     * @returns The display text for the sepecified DMF rate period on if known, otherwise returns empty string.
     */
    transform(value) {
        if (value) {
            return ilu_dmf_rate_period_enum_1.IluDmfRatePeriod[value];
        }
        return "";
    }
}
exports.IluDmfRatePeriodTextPipe = IluDmfRatePeriodTextPipe;
IluDmfRatePeriodTextPipe.ɵfac = function IluDmfRatePeriodTextPipe_Factory(t) { return new (t || IluDmfRatePeriodTextPipe)(); };
IluDmfRatePeriodTextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "iluDmfRatePeriodText", type: IluDmfRatePeriodTextPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluDmfRatePeriodTextPipe, [{
        type: core_1.Pipe,
        args: [{ name: "iluDmfRatePeriodText" }]
    }], null, null); })();
