"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
/**
 * Returns the sum of a property from objects in an array.
 *
 * @example
 * <span>{{ingoingContributions | sum:"value"}}</span>
 */
class SumPipe {
    /**
     * Returns the sum of a property from objects in an array.
     *
     * @param items The array of objects.
     * @param property The property name to sum.
     * @returns The sum of the property values.
     */
    transform(items, property) {
        if (items && items.length > 0 && property) {
            return items.reduce((acc, item) => acc + Number(item[property]), 0);
        }
        return 0;
    }
}
exports.SumPipe = SumPipe;
SumPipe.ɵfac = function SumPipe_Factory(t) { return new (t || SumPipe)(); };
SumPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "sum", type: SumPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SumPipe, [{
        type: core_1.Pipe,
        args: [{ name: "sum" }]
    }], null, null); })();
