"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const _ = require("underscore");
require("../../common/dto/batch-result");
require("../dtos/simple-enquiry-dto");
require("../enums/enquiry-accessibility-requirement.enum");
require("../dtos/lead-card-dto");
require("../ilu-status/dto/tags-save-dto");
require("../dtos/tag-dto");
require("../dtos/ilu-leads-dashboard-dto");
require("../dtos/ilu-leads-dashboard-filter-dto");
require("../dtos/ilu-finanace-details-dto");
require("../dtos/accomodation-dto");
require("../dtos/independent-living-allocation-dmf-frequencies-dto");
require("../dtos/independent-living-allocation-dmf-period-rate-type-dto");
require("../dtos/independent-living-allocation-dmf-settlement-cost-type-dto");
require("../dtos/ilu-agreement-template-dto");
require("../dtos/independent-living-allocation-dmf-calculate-totals-dto");
require("../dtos/independent-living-allocation-dmf-totals-dto");
require("../dtos/ilu-finalised-settlement-dto");
require("../dtos/optional-service-dto");
const i0 = require("@angular/core");
class IndependentLivingService {
    constructor(dataService) {
        this.dataService = dataService;
        /**
         * Gets the ILU leads from the API for the initial ILU lead dashboard load.
         *
         * @param take Number of ILU enquiry records to return for each status.
         * @param filter The ILU lead dashboard filter criteria.
         * @returns A Promise that will be resolved or rejected with a @see ILULeadsDashboardDto object.
         */
        this.getDashboardLeads = (take, filter) => {
            const parameterObject = _.extend({}, filter, {
                take: take,
            });
            return this.dataService.get("enquiries/ilu-dashboard/", true, parameterObject);
        };
        /**
         * Gets the ILU leads from the API for the initial ILU lead dashboard load.
         *
         * @param status The ILU Lead status to fetch ILU enquiry records for.
         * @param skip Number of ILU enquiry records to skip.
         * @param take Number of ILU enquiry records to return.
         * @param filter The ILU lead dashboard filter criteria.
         * @param notIncludingItemsAfter Optional. Only fetch ILU enquiry records on or before this date. If null ignore ILU enquiry creatation date.
         * @returns A Promise that will be resolved or rejected with a @see ILULeadsDashboardDto object.
         */
        this.getLeadsForDashboardByStatus = (status, skip, take, filter, notIncludingItemsAfter) => {
            const parameterObject = _.extend({}, filter, {
                status: status,
                skip: skip,
                take: take,
                notIncludingItemsAfter: notIncludingItemsAfter,
            });
            return this.dataService.get("enquiries/ilu-dashboard/status/", true, parameterObject);
        };
        this.updateEnquiryAccessibilityRequirement = (enquiryId, enquiryAccessibilityRequirement) => {
            return this.dataService.put(`enquiries/${enquiryId}/accessibility-requirement`, { enquiryAccessibilityRequirement });
        };
        /**
         * Gets the archived ILU leads from the API.
         *
         * @param skip Optional number of archived ILU enquiry records to skip from the start.
         * @param take Optional number of archived ILU enquiry records to return.
         * @returns A Promise that will be resolved or rejected with a @see BatchResult object for @see SimpleEnquiryDto.
         */
        this.getArchivedLeads = (skip, take) => {
            const parameterObject = _.extend({}, {
                skip: skip,
                take: take,
            });
            return this.dataService.get("enquiries/ilu-dashboard/archived", true, parameterObject);
        };
        /**
         * Updates the archived card order to move the enquiryId after afterEnquiryId.
         *
         * @param enquiryId The enquiryId of the card target to move.
         * @param afterEnquiryId The enquiryId of the card to move the target card after. Set to null if moving the target card to the front of the list.
         * @returns A Promise that will be resolved or rejected.
         */
        this.updateArchivedCardOrderNumber = (enquiryId, afterEnquiryId) => {
            return this.dataService.put("enquiries/ilu-dashboard/archived/card-order", {
                enquiryId: enquiryId,
                afterEnquiryId: afterEnquiryId,
            }, true);
        };
        /**
         * Moves an ILU lead to archive.
         *
         * @param enquiryId The equiryId of the ILU lead to archive.
         * @returns A Promise that will be resolved or rejected.
         */
        this.archive = (enquiryId) => {
            return this.dataService.put(`enquiries/${enquiryId}/archive`, null, true);
        };
        /**
         * Re-instates an ILU lead from archive.
         *
         * @param enquiryId The equiryId of the ILU lead to be re-instated from archive.
         * @returns A Promise that will be resolved or rejected.
         */
        this.reinstateFromArchive = (enquiryId) => {
            return this.dataService.put(`enquiries/${enquiryId}/reinstate-from-archive`, null, true);
        };
        this.saveIlaTags = (tagsSaveDto) => {
            return this.dataService.post("enquiries/save-ila-tags", tagsSaveDto);
        };
        this.getIlaTags = (residencyId) => {
            return this.dataService.get(`enquiries/get-ila-tags/${residencyId}`, true, null, true);
        };
        this.getAllTagsForFacility = () => {
            let facilityId = this.dataService.getSelectedFacilityId();
            return this.dataService.get(`enquiries/${facilityId}/tags`, true, null, true);
        };
        /**
         * Fetches Leads Export Report in a Excel file as binary stream.
         *
         * @param filter The ILU lead dashboard filter criteria.
         * @returns A Promise that will be resolved or rejected with a excel file in a binary data array.
         */
        this.getLeadsExportReportExcelStream = (filter) => {
            return this.dataService.getArrayBuffer("enquiries/ilu-dashboard/export", true, filter);
        };
        /**
         * Fetches finance details for an independentLivingAllocationId.
         *
         * @param independentLivingAllocationId The independentLivingAllocationId.
         * @returns A Promise that will be resolved or rejected with a the finance details for the provided independentLivingAllocationId.
         */
        this.getFinanceDetails = (independentLivingAllocationId) => {
            return this.dataService.get("enquiries/finance/" + independentLivingAllocationId, true);
        };
        /**
         * Saves finance details to the API.
         *
         * @param iluFinanceDetailsDto The finance details.
         * @returns A Promise that will be resolved or rejected.
         */
        this.saveFinanceDetails = (iluFinanceDetailsDto) => {
            return this.dataService.put("enquiries/finance/" + iluFinanceDetailsDto.independentLivingAllocationId, iluFinanceDetailsDto);
        };
        /**
         * Fetches finance details for an independentLivingAllocationId.
         *
         * @param independentLivingAllocationId The independentLivingAllocationId.
         * @returns A Promise that will be resolved or rejected with a the finance details for the provided independentLivingAllocationId.
         */
        this.getUnits = () => {
            return this.dataService.get("accommodations/units/allocatable", true);
        };
        /**
         * Fetches the ILU DMF frequencies.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see IndependentLivingAllocationDmfFrequenciesDto objects.
         */
        this.getDmfFrequencies = () => {
            return this.dataService.get("enquiries/dmf/frequencies", true);
        };
        /**
         * Fetches the ILU DMF period rate types.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see IndependentLivingAllocationDmfPeriodRateTypeDto objects.
         */
        this.getDmfPeriodRateTypes = () => {
            return this.dataService.get("enquiries/dmf/period-rate-type", true);
        };
        /**
         * Fetches the ILU DMF settlement cost types.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see IndependentLivingAllocationDmfSettlementCostTypeDto objects.
         */
        this.getDmfSettlementCostTypes = () => {
            return this.dataService.get("enquiries/dmf/settlement-cost-type", true);
        };
        /**
         * Fetches the ILU agreement templates.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see IluAgreementTemplateDto objects.
         */
        this.getIluAgreementTemplates = () => {
            return this.dataService.get("enquiries/agreement-template", true);
        };
        /**
         * Fetches the Optional services.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see OptionalServiceDto objects.
         */
        this.getOptionalServices = (includeStopped = true) => {
            return this.dataService.get(`facilities/optional-services?includeStopped=${includeStopped}`, true);
        };
        /**
         * Request the server to calculate the total for the current ILU DMF agreement.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see IndependentLivingAllocationDmfTotalsDto objects.
         */
        this.calculateDmfTotals = (independentLivingAllocationDmfCalculateTotalsDto) => {
            return this.dataService.post("enquiries/dmf/totals", independentLivingAllocationDmfCalculateTotalsDto, true);
        };
        /**
         * Finalise an ILU settlement in the API.
         *
         * @param iluFinaliseSettlementDto ILU settlement details
         * @returns A Promise that will be resolved or rejected.
         */
        this.finaliseSettlement = (iluFinaliseSettlementDto) => {
            return this.dataService.post("allocations/settlement", iluFinaliseSettlementDto);
        };
        /**
         * Show the high level site loading notification.
         */
        this.showLoadingNotification = () => {
            this.dataService.beforeRequest();
        };
        /**
         * Hide the high level site loading notification.
         */
        this.hideLoadingNotification = () => {
            this.dataService.finallyCallback();
        };
    }
    updateCardsOrderNumber(index, enquiryId) {
        return this.dataService.put("enquiries/card-order", {
            orderNumber: index,
            enquiryId: enquiryId,
        });
    }
    updateEnquiryStatus(enquiryId, status, orderNumber = null) {
        return this.dataService.put("enquiries/lead-status", {
            enquiryId: enquiryId,
            status: status,
            orderNumber: orderNumber,
        });
    }
    updatePreAdmissionIluStatus(residencyId, status) {
        return this.dataService.post("enquiries/update-pre-admission-ilu-status", {
            residencyId: residencyId,
            status: status,
        });
    }
    /**
     * Marks an ILU lead as deleted.
     *
     * @param enquiryId The equiryId of the ILU lead to be marked as deleted.
     * @param hideLoadingNotification Optional. Hide the global loading notification. Defaults to false.
     * @returns A Promise that will be resolved or rejected.
     */
    deleteEnquiry(enquiryId, hideLoadingNotification = false) {
        return this.dataService.delete("enquiries/" + enquiryId, hideLoadingNotification);
    }
}
exports.IndependentLivingService = IndependentLivingService;
IndependentLivingService.ɵfac = function IndependentLivingService_Factory(t) { return new (t || IndependentLivingService)(i0.ɵɵinject("dataService")); };
IndependentLivingService.ɵprov = i0.ɵɵdefineInjectable({ token: IndependentLivingService, factory: IndependentLivingService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IndependentLivingService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ["dataService"]
            }] }]; }, null); })();
