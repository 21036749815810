"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../dtos/ilu-finanace-details-dto");
const billing_recurrence_interval_enum_1 = require("../enums/billing-recurrence-interval.enum");
const stop_billing_event_enum_1 = require("../enums/stop-billing-event.enum");
const i0 = require("@angular/core");
/**
 * Returns a short description of how often and until when the provided optional service's will be billed.
 *
 * @example
 * <span>{{optionalService | optionalServiceBillingPeriodDescription }}</span>
 */
class OptionalServiceBillingDescriptionPipe {
    /**
     * Returns a short description of how often and until when the provided optional service's will be billed.
     *
     * @param value The option service.
     * @returns The short description of how often and until when the provided optional service's will be billed.
     */
    transform(value) {
        if (value) {
            return `Billed ${billing_recurrence_interval_enum_1.BillingRecurrenceInterval[value.billingPeriod].toLowerCase()} until ${stop_billing_event_enum_1.StopBillingEvent[value.stopBillingEvent].toLowerCase()}`;
        }
        return null;
    }
}
exports.OptionalServiceBillingDescriptionPipe = OptionalServiceBillingDescriptionPipe;
OptionalServiceBillingDescriptionPipe.ɵfac = function OptionalServiceBillingDescriptionPipe_Factory(t) { return new (t || OptionalServiceBillingDescriptionPipe)(); };
OptionalServiceBillingDescriptionPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "optionalServiceBillingDescription", type: OptionalServiceBillingDescriptionPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OptionalServiceBillingDescriptionPipe, [{
        type: core_1.Pipe,
        args: [{ name: "optionalServiceBillingDescription" }]
    }], null, null); })();
