"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const dnaR_input_model_1 = require("../dnaR-input/dnaR-input.model");
const dnaR_input_type_enum_1 = require("./enums/dnaR-input-type.enum");
const coercion_1 = require("@angular/cdk/coercion");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
function DnaRInput_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.prefix);
} }
const _c0 = function (a0, a1) { return { "disabled": a0, "toRight": a1 }; };
function DnaRInput_input_3_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 7);
    i0.ɵɵlistener("keyup", function DnaRInput_input_3_Template_input_keyup_0_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.inputChanged($event); })("change", function DnaRInput_input_3_Template_input_change_0_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.inputChanged($event); })("blur", function DnaRInput_input_3_Template_input_blur_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.onBlur(); })("keydown.enter", function DnaRInput_input_3_Template_input_keydown_enter_0_listener($event) { i0.ɵɵrestoreView(_r6); return $event.preventDefault(); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r1.currentValue)("placeholder", ctx_r1.currentSettings.placeholder)("disabled", ctx_r1.disabled)("ngClass", i0.ɵɵpureFunction2(4, _c0, ctx_r1.disabled, ctx_r1.toRight));
} }
function DnaRInput_input_4_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 7);
    i0.ɵɵlistener("keyup", function DnaRInput_input_4_Template_input_keyup_0_listener($event) { i0.ɵɵrestoreView(_r11); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.inputChanged($event); })("change", function DnaRInput_input_4_Template_input_change_0_listener($event) { i0.ɵɵrestoreView(_r11); const ctx_r12 = i0.ɵɵnextContext(); return ctx_r12.inputChanged($event); })("blur", function DnaRInput_input_4_Template_input_blur_0_listener() { i0.ɵɵrestoreView(_r11); const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.onBlur(); })("keydown.enter", function DnaRInput_input_4_Template_input_keydown_enter_0_listener($event) { i0.ɵɵrestoreView(_r11); return $event.preventDefault(); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r2.currentValue)("placeholder", ctx_r2.currentSettings.placeholder)("disabled", ctx_r2.disabled)("ngClass", i0.ɵɵpureFunction2(4, _c0, ctx_r2.disabled, ctx_r2.toRight));
} }
function DnaRInput_span_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r3.postfix);
} }
function DnaRInput_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r4.currentError, " ");
} }
const _c1 = function (a0, a1, a2) { return { "disabled": a0, "invalid": a1, "valid": a2 }; };
/* Required for form validation */
exports.INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: forms_1.NG_VALUE_ACCESSOR,
    useExisting: core_1.forwardRef(() => DnaRInput),
    multi: true
};
class DnaRInput {
    constructor() {
        this.hasError = false;
        this.dnaRInputType = dnaR_input_type_enum_1.DnaRInputType;
        this.currentSettings = Object.assign({}, dnaR_input_model_1.DefaultSettings);
        this.currentToRight = false;
        this.currentControlName = null;
        this.currentFormControl = null;
        this.isDisabled = false;
        this.isInputTooLong = false;
        this.tooLong = false;
        this.isDuplicateInput = false;
        this.duplicate = false;
        this.isValidate = false;
        this.currentError = null;
        // I'm a TextBox, of course my value can't be undefined or null.
        this.currentValue = "";
        this.onComponentChange = new core_1.EventEmitter();
    }
    set settings(value) {
        if (value) {
            Object.assign(this.currentSettings, value);
        }
    }
    set disallowedValues(value) {
        if (value !== null && value !== undefined) {
            this.currentSettings.disallowedValues = value;
        }
    }
    get inputType() {
        return this.currentSettings.inputType;
    }
    set inputType(value) {
        if (value) {
            this.currentSettings.inputType = value;
        }
    }
    set placeholder(value) {
        if (value != null) {
            this.currentSettings.placeholder = value;
        }
    }
    /**
     * Whether the value is aligned to the right of the component.
     */
    get toRight() {
        return this.currentToRight;
    }
    set toRight(value) {
        this.currentToRight = coercion_1.coerceBooleanProperty(value);
    }
    set formControlName(value) {
        if (value != null) {
            this.currentControlName = value;
        }
    }
    set formControl(value) {
        if (value != null) {
            this.currentFormControl = value;
        }
    }
    /**
     * Whether the component is disabled.
     */
    get disabled() {
        return this.isDisabled;
    }
    set disabled(value) {
        this.isDisabled = coercion_1.coerceBooleanProperty(value);
    }
    set required(value) {
        if (value != null) {
            this.currentSettings.isRequired = value;
        }
    }
    set tooLongError(value) {
        if (value != null) {
            this.currentSettings.tooLongError = value;
        }
    }
    set duplicateInputError(value) {
        if (value != null) {
            this.currentSettings.duplicateInputError = value;
        }
    }
    set requiredError(value) {
        if (value != null) {
            this.currentSettings.requiredError = value;
        }
    }
    get value() {
        return this.currentValue;
    }
    set value(value) {
        if (value === undefined || value === null) {
            this.currentValue = "";
        }
        else {
            this.currentValue = value;
        }
    }
    ngOnInit() {
        this.validateValue(); // validate initial value
    }
    onBlur() {
        var _a;
        (_a = this.onTouched) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    writeValue(value) {
        this.value = value;
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    ngOnChanges() {
        this.currentError = this.error;
        this.currentValue = this.value;
        this.isValidate = this.validate;
        this.isInputTooLong = this.tooLong;
        this.isDuplicateInput = this.duplicate;
        if (this.validate) {
            this.validateValue();
            this.emitComponentChangeEvent();
        }
    }
    isValid() {
        return this.validRequired() &&
            this.noErrors();
    }
    validRequired() {
        var isNotRequired = !this.currentSettings.isRequired;
        var isRequired = this.currentSettings.isRequired;
        var valueExists = (this.currentValue !== null && this.currentValue !== undefined && this.currentValue !== '');
        var valueIsAllowed = this.valueIsAllowed(this.currentValue);
        return (isNotRequired ||
            (isRequired &&
                (valueExists &&
                    valueIsAllowed)));
    }
    noErrors() {
        return (this.currentError === null) || (this.currentError === '');
    }
    inputChanged($event) {
        var _a, _b;
        this.currentValue = $event.target.value;
        (_a = this.onChange) === null || _a === void 0 ? void 0 : _a.call(this, $event.target.value);
        (_b = this.onTouched) === null || _b === void 0 ? void 0 : _b.call(this);
        this.validateValue();
        this.emitComponentChangeEvent();
    }
    validateValue() {
        this.currentError = null;
        this.hasError = false;
        var validRequired = this.validRequired();
        if (!validRequired) {
            this.currentError = this.currentSettings.requiredError;
            this.hasError = true;
            if (this.currentFormControl) {
                this.currentFormControl.markAsTouched(); // got to mark form controller as touched otherwise validation will not kick in
                this.currentFormControl.setErrors({ "required": true });
            }
            return;
        }
        if (this.isInputTooLong) {
            this.currentError = this.currentSettings.tooLongError;
            this.hasError = true;
            return;
        }
        if (this.isDuplicateInput) {
            this.currentError = this.currentSettings.duplicateInputError;
            this.hasError = true;
            return;
        }
    }
    /**
     * Wheather the component has a @see prefix value.
     *
     * @returns true if @see prefix has a value, otherwise false.
     * @docs-private
     */
    hasPrefix() {
        return !!this.prefix;
    }
    /**
     * Wheather the component has a @see postfix value.
     *
     * @returns true if @see postfix has a value, otherwise false.
     * @docs-private
     */
    hasPostfix() {
        return !!this.postfix;
    }
    emitComponentChangeEvent() {
        var EventEmitterValue = (this.currentError !== null && this.currentError !== undefined)
            ? { controlName: this.currentControlName, value: this.currentValue, isInValid: true, error: this.currentError }
            : { controlName: this.currentControlName, value: this.currentValue, isInValid: false, error: null };
        this.onComponentChange.emit(EventEmitterValue);
    }
    valueIsAllowed(currentValue) {
        var allowed = true;
        if (this.currentSettings.disallowedValues.length == 0) {
            return true;
        }
        this.currentSettings.disallowedValues.forEach((disallowed) => {
            if (this.inputType === this.dnaRInputType.currency && disallowed != '') {
                disallowed = Number(disallowed);
                currentValue = Number(currentValue);
            }
            if (disallowed == currentValue) {
                allowed = false;
            }
        });
        return allowed;
    }
}
exports.DnaRInput = DnaRInput;
DnaRInput.ɵfac = function DnaRInput_Factory(t) { return new (t || DnaRInput)(); };
DnaRInput.ɵcmp = i0.ɵɵdefineComponent({ type: DnaRInput, selectors: [["dnaR-input"]], inputs: { settings: "settings", disallowedValues: "disallowedValues", inputType: "inputType", placeholder: "placeholder", prefix: "prefix", postfix: "postfix", toRight: "toRight", formControlName: "formControlName", formControl: "formControl", disabled: "disabled", required: "required", tooLong: "tooLong", tooLongError: "tooLongError", duplicate: "duplicate", duplicateInputError: "duplicateInputError", validate: "validate", requiredError: "requiredError", error: "error", value: "value" }, outputs: { onComponentChange: "onComponentChange" }, features: [i0.ɵɵProvidersFeature([exports.INPUT_CONTROL_VALUE_ACCESSOR]), i0.ɵɵNgOnChangesFeature], decls: 7, vars: 10, consts: [[1, "dnaRInputContainer"], [1, "dnaRComponent", "dnaRInput", 3, "ngClass"], ["class", "prefix", 4, "ngIf"], ["type", "text", "class", "input", 3, "value", "placeholder", "disabled", "ngClass", "keyup", "change", "blur", "keydown.enter", 4, "ngIf"], ["class", "postfix", 4, "ngIf"], ["class", "error", 4, "ngIf"], [1, "prefix"], ["type", "text", 1, "input", 3, "value", "placeholder", "disabled", "ngClass", "keyup", "change", "blur", "keydown.enter"], [1, "postfix"], [1, "error"]], template: function DnaRInput_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, DnaRInput_span_2_Template, 2, 1, "span", 2);
        i0.ɵɵtemplate(3, DnaRInput_input_3_Template, 1, 7, "input", 3);
        i0.ɵɵtemplate(4, DnaRInput_input_4_Template, 1, 7, "input", 3);
        i0.ɵɵtemplate(5, DnaRInput_span_5_Template, 2, 1, "span", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, DnaRInput_div_6_Template, 2, 1, "div", 5);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(6, _c1, ctx.disabled, ctx.isValidate && ctx.hasError, ctx.isValidate && !ctx.hasError));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.hasPrefix());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.inputType === ctx.dnaRInputType.text);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.inputType === ctx.dnaRInputType.currency);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.hasPostfix());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isValidate && ctx.hasError);
    } }, directives: [i1.NgClass, i1.NgIf], styles: ["dnaR-input[_ngcontent-%COMP%] {\n  display: inline-block;\n}\ninput[_ngcontent-%COMP%]:focus {\n  outline: none !important;\n}\n.dnaRComponent[_ngcontent-%COMP%] {\n  display: flex;\n  justify-content: space-between;\n  border: 1px solid #AAAAAA;\n  border-radius: 2px;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0;\n}\n.dnaRComponent[_ngcontent-%COMP%]:hover {\n  border-color: #000000;\n}\n.dnaRComponent[_ngcontent-%COMP%]:focus-within {\n  border-color: #008381;\n}\n.valid[_ngcontent-%COMP%] {\n  border-color: #AAAAAA;\n}\n.invalid[_ngcontent-%COMP%] {\n  border-color: #C53535 !important;\n}\n.dnaRInputContainer[_ngcontent-%COMP%] {\n  display: inline-block;\n}\n.dnaRInput[_ngcontent-%COMP%]   input[_ngcontent-%COMP%] {\n  padding: 5px;\n  height: 32px;\n  outline: none;\n  border: 0;\n  color: #414141;\n  width: 100%;\n}\n.dnaRInput[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]::placeholder {\n  \n  color: #757575;\n  opacity: 1;\n  \n}\n.dnaRInput[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:-ms-input-placeholder {\n  \n  color: #757575;\n}\n.dnaRInput[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]::-ms-input-placeholder {\n  \n  color: #757575;\n}\n.dnaRInput[_ngcontent-%COMP%]   .prefix[_ngcontent-%COMP%] {\n  padding: 0 5px;\n  line-height: 32px;\n}\n.dnaRInput[_ngcontent-%COMP%]   .postfix[_ngcontent-%COMP%] {\n  padding: 0 5px;\n  line-height: 32px;\n}\n.dnaRInput.disabled[_ngcontent-%COMP%] {\n  border-color: #D2D2D2;\n  background-color: #F5F5F5;\n  color: #757575;\n}\n.toRight[_ngcontent-%COMP%] {\n  text-align: right;\n}\n.error[_ngcontent-%COMP%] {\n  color: #C53535;\n  float: left;\n  font-size: 12px;\n  padding-bottom: 5px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRInput, [{
        type: core_1.Component,
        args: [{
                selector: 'dnaR-input',
                templateUrl: './dnaR-input.component.html',
                styleUrls: ['./dnaR-input.component.less'],
                providers: [exports.INPUT_CONTROL_VALUE_ACCESSOR]
            }]
    }], function () { return []; }, { settings: [{
            type: core_1.Input
        }], disallowedValues: [{
            type: core_1.Input
        }], inputType: [{
            type: core_1.Input
        }], placeholder: [{
            type: core_1.Input
        }], prefix: [{
            type: core_1.Input
        }], postfix: [{
            type: core_1.Input
        }], toRight: [{
            type: core_1.Input
        }], formControlName: [{
            type: core_1.Input
        }], formControl: [{
            type: core_1.Input
        }], disabled: [{
            type: core_1.Input
        }], required: [{
            type: core_1.Input
        }], tooLong: [{
            type: core_1.Input
        }], tooLongError: [{
            type: core_1.Input
        }], duplicate: [{
            type: core_1.Input
        }], duplicateInputError: [{
            type: core_1.Input
        }], validate: [{
            type: core_1.Input
        }], requiredError: [{
            type: core_1.Input
        }], error: [{
            type: core_1.Input
        }], value: [{
            type: core_1.Input
        }], onComponentChange: [{
            type: core_1.Output
        }] }); })();
