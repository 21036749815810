"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_dmf_commences_on_enum_1 = require("../enums/ilu-dmf-commences-on.enum");
const i0 = require("@angular/core");
/**
 * Returns the display text for the specified @see IluDmfCommencesOn.
 *
 * @example
 * <span>{{commencesdOn | iluDmfCommencesOnText}}</span>
 */
class IluDmfCommencesOnTextPipe {
    /**
     * Returns the display text for the specified @see IluDmfCommencesOn.
     *
     * @param value The DMF commences on value.
     * @returns The display text for the sepecified DMF commences on if known, otherwise returns empty string.
     */
    transform(value) {
        if (value) {
            if (value === ilu_dmf_commences_on_enum_1.IluDmfCommencesOn.Admission) {
                return "Admission date";
            }
            if (value === ilu_dmf_commences_on_enum_1.IluDmfCommencesOn.ContractSigned) {
                return "Contract date";
            }
        }
        return "";
    }
}
exports.IluDmfCommencesOnTextPipe = IluDmfCommencesOnTextPipe;
IluDmfCommencesOnTextPipe.ɵfac = function IluDmfCommencesOnTextPipe_Factory(t) { return new (t || IluDmfCommencesOnTextPipe)(); };
IluDmfCommencesOnTextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "iluDmfCommencesOnText", type: IluDmfCommencesOnTextPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluDmfCommencesOnTextPipe, [{
        type: core_1.Pipe,
        args: [{ name: "iluDmfCommencesOnText" }]
    }], null, null); })();
