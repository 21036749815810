"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_finanace_details_dto_1 = require("../dtos/ilu-finanace-details-dto");
const i0 = require("@angular/core");
/**
 * Returns the residency's resident combined name first & last name.
 *
 * @example
 * <span>{{residency | residencyResidentName:true}}</span>
 */
class ResidencyResidentNamePipe {
    /**
     * Returns the residency's resident combined name first & last name.
     *
     * @param value The residendency.
     * @param lastNameAllCaps Optionally format the last name to be all caps.
     * @returns The residency's resident combined name first & last name. Return empty string if residency or the resident is null or undefined.
     */
    transform(value, lastNameAllCaps = false) {
        if (value && value.resident) {
            return ilu_finanace_details_dto_1.getResidentName(value.resident, lastNameAllCaps);
        }
        return "";
    }
}
exports.ResidencyResidentNamePipe = ResidencyResidentNamePipe;
ResidencyResidentNamePipe.ɵfac = function ResidencyResidentNamePipe_Factory(t) { return new (t || ResidencyResidentNamePipe)(); };
ResidencyResidentNamePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "residencyResidentName", type: ResidencyResidentNamePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidencyResidentNamePipe, [{
        type: core_1.Pipe,
        args: [{ name: "residencyResidentName" }]
    }], null, null); })();
