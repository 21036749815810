"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Popup display triggers.
 */
var DnaRPopoverTriggerEnum;
(function (DnaRPopoverTriggerEnum) {
    DnaRPopoverTriggerEnum["Click"] = "click";
    DnaRPopoverTriggerEnum["Hover"] = "hover";
})(DnaRPopoverTriggerEnum = exports.DnaRPopoverTriggerEnum || (exports.DnaRPopoverTriggerEnum = {}));
