"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const overlay_1 = require("@angular/cdk/overlay");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const portal_1 = require("@angular/cdk/portal");
const dnaR_popover_trigger_enum_1 = require("./dnaR-popover-trigger.enum");
const i0 = require("@angular/core");
const i1 = require("@angular/cdk/overlay");
/**
 * Directive to display a popover when element is clicked.
 *
 * @example
 * <button [dnaRPopover]="popoverContent">Show Popover</button>
 * <ng-template #popoverContent>
 *   Popover content
 * </ng-template>
 *
 * @description Based on code from https://github.com/Dornhoth/info-popup & https://levelup.gitconnected.com/help-popup-with-angular-material-cdk-overlay-babc2ab127a
 */
class DnaRPopoverDirective {
    /**
     * Initializes a new instance of the InfoPopupDirective class.
     *
     * @param overlay The material Overlay service.
     * @param vcr The view container ref.
     */
    constructor(overlay, vcr) {
        this.overlay = overlay;
        this.vcr = vcr;
        this._triggerOn = dnaR_popover_trigger_enum_1.DnaRPopoverTriggerEnum.Click;
        /**
         * Notifies when the directive unsubscribes from the backdrop click.
         */
        this.unsubscribeToBackdropClick = new rxjs_1.Subject();
    }
    /**
     * The trigger to display the popover. Defaults to @see DnaRPopoverTriggerEnum.Click.
     */
    set triggerOn(value) {
        this._triggerOn = Object.values(dnaR_popover_trigger_enum_1.DnaRPopoverTriggerEnum).includes(value) ? value : dnaR_popover_trigger_enum_1.DnaRPopoverTriggerEnum.Click;
    }
    /**
     * @inheritdoc
     */
    ngOnInit() {
        this.createOverlay();
    }
    /**
     * Attaches the overlay when the host is clicked.
     */
    clickHost() {
        if (this._triggerOn === dnaR_popover_trigger_enum_1.DnaRPopoverTriggerEnum.Click) {
            this.attachOverlay();
        }
    }
    /**
     * Attaches the overlay when the host is moused over.
     */
    mouseOverHost() {
        if (this._triggerOn === dnaR_popover_trigger_enum_1.DnaRPopoverTriggerEnum.Hover) {
            this.attachOverlay();
        }
    }
    /**
     * @inheritdoc
     */
    ngOnDestroy() {
        this.detachOverlay();
        this.unsubscribeToBackdropClick.next();
        this.unsubscribeToBackdropClick.complete();
    }
    /**
     * Create and configure the overlay for the popover.
     */
    createOverlay() {
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.vcr.element.nativeElement)
            .withPositions([new overlay_1.ConnectionPositionPair({ originX: "end", originY: "bottom" }, { overlayX: "start", overlayY: "top" })])
            .withPush(false);
        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: "dna-r-popover-backdrop",
        });
        this.overlayRef
            .backdropClick()
            .pipe(operators_1.takeUntil(this.unsubscribeToBackdropClick))
            .subscribe(() => {
            this.detachOverlay();
        });
    }
    /**
     * Attaches the popover template to the overlay. aka Shows the popover.
     */
    attachOverlay() {
        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(new portal_1.TemplatePortal(this.dnaRPopover, this.vcr));
        }
    }
    /**
     * Deattach the popover template from the overlay. aka Hides the popover.
     */
    detachOverlay() {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
exports.DnaRPopoverDirective = DnaRPopoverDirective;
DnaRPopoverDirective.ɵfac = function DnaRPopoverDirective_Factory(t) { return new (t || DnaRPopoverDirective)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ViewContainerRef)); };
DnaRPopoverDirective.ɵdir = i0.ɵɵdefineDirective({ type: DnaRPopoverDirective, selectors: [["", "dnaRPopover", ""]], hostBindings: function DnaRPopoverDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function DnaRPopoverDirective_click_HostBindingHandler() { return ctx.clickHost(); })("mouseover", function DnaRPopoverDirective_mouseover_HostBindingHandler() { return ctx.mouseOverHost(); });
    } }, inputs: { dnaRPopover: "dnaRPopover", triggerOn: "triggerOn" } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRPopoverDirective, [{
        type: core_1.Directive,
        args: [{
                selector: "[dnaRPopover]",
            }]
    }], function () { return [{ type: i1.Overlay }, { type: i0.ViewContainerRef }]; }, { dnaRPopover: [{
            type: core_1.Input
        }], triggerOn: [{
            type: core_1.Input
        }], clickHost: [{
            type: core_1.HostListener,
            args: ["click"]
        }], mouseOverHost: [{
            type: core_1.HostListener,
            args: ['mouseover']
        }] }); })();
