"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@angular/forms");
const isNum_1 = require("../../../common/validators/isNum");
/**
 * Validator that requires the control have a value that is a number.
 *
 * @returns An error map with the `notANumber` property if the number validation check fails, otherwise `null`.
 */
function isNumberValidator(control) {
    const value = control.value;
    if (!value) {
        return null;
    }
    if (!isNum_1.isNum(value)) {
        return { notANumber: true };
    }
    return null;
}
exports.isNumberValidator = isNumberValidator;
