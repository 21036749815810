"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Period type select option.
 */
class PeriodTypeOption {
}
exports.PeriodTypeOption = PeriodTypeOption;
/**
 * Standard collection of period type select options.
 *
 * @returns A standard array of @see PeriodTypeOptions.
 */
function getStandardPeriodTypes() {
    let periodTypeOptions = [
        { id: 'week', name: 'Weeks', disabled: false },
        { id: 'month', name: 'Month/s', disabled: false },
        { id: 'year', name: 'Year/s', disabled: false }
    ];
    return periodTypeOptions;
}
exports.getStandardPeriodTypes = getStandardPeriodTypes;
