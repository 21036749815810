"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("angular");
const core_1 = require("@angular/core");
const root_scope_provider_1 = require("../../common/providers/root-scope.provider");
const i0 = require("@angular/core");
/**
 * Provides the current selected facility functionality.
 *
 * This hides and isolates the injecting $rootScope into Angular code just in case we want to change extend how this functionality is provided in the future.
 */
class SelectedFacilityService {
    /**
     * Initializes a new instance of the @see SelectedFacilityService class.
     *
     * @param rootScope Provides access to the AngularJS $rootScope.
     */
    constructor(rootScope) {
        this.rootScope = rootScope;
    }
    /**
     * Returns the details of the current selected facility.
     *
     * @returns The details of the current selected facility.
     */
    getSelectedFacility() {
        return {
            facilityType: this.rootScope.facilityType,
            facilityValidated: this.rootScope.facilityValidated,
            isBillingIntegratedFacility: this.rootScope.isBillingIntegratedFacility
        };
    }
}
exports.SelectedFacilityService = SelectedFacilityService;
SelectedFacilityService.ɵfac = function SelectedFacilityService_Factory(t) { return new (t || SelectedFacilityService)(i0.ɵɵinject(root_scope_provider_1.ROOT_SCOPE)); };
SelectedFacilityService.ɵprov = i0.ɵɵdefineInjectable({ token: SelectedFacilityService, factory: SelectedFacilityService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SelectedFacilityService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: [root_scope_provider_1.ROOT_SCOPE]
            }] }]; }, null); })();
/**
 * Details of the selected facility.
 *
 * These are only the details stored in the AngularJS $RootScope.
 */
class SelectedFacility {
}
exports.SelectedFacility = SelectedFacility;
