"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dnaR_search_component_1 = require("./dnaR-search.component");
const shared_module_1 = require("../../shared.module");
const residents_module_1 = require("../../../residents/residents.module");
const dnaR_spinner_module_1 = require("../dnaR-spinner/dnaR-spinner.module");
const i0 = require("@angular/core");
class DnaRSearchModule {
    static forRoot() {
        return {
            ngModule: DnaRSearchModule
        };
    }
}
exports.DnaRSearchModule = DnaRSearchModule;
DnaRSearchModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRSearchModule });
DnaRSearchModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRSearchModule_Factory(t) { return new (t || DnaRSearchModule)(); }, imports: [[shared_module_1.SharedModule, residents_module_1.ResidentsModule, dnaR_spinner_module_1.DnaRSpinnerModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRSearchModule, { declarations: [dnaR_search_component_1.DnaRSearch], imports: [shared_module_1.SharedModule, residents_module_1.ResidentsModule, dnaR_spinner_module_1.DnaRSpinnerModule], exports: [dnaR_search_component_1.DnaRSearch] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSearchModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [shared_module_1.SharedModule, residents_module_1.ResidentsModule, dnaR_spinner_module_1.DnaRSpinnerModule],
                declarations: [dnaR_search_component_1.DnaRSearch],
                exports: [dnaR_search_component_1.DnaRSearch],
            }]
    }], null, null); })();
