"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const common_1 = require("@angular/common");
const show_hide_password_directive_1 = require("./directives/show-hide-password.directive");
const enquiryAccessibilityRequirementDescription_pipe_1 = require("./pipes/enquiryAccessibilityRequirementDescription.pipe");
const core_2 = require("@angular/material/core");
const dnaR_paginator_component_1 = require("./components/dnaR-paginator/dnaR-paginator.component");
const button_1 = require("@angular/material/button");
const dnaR_back_to_top_component_1 = require("./components/dnaR-back-to-top/dnaR-back-to-top.component");
const dnaR_popover_directive_1 = require("./directives/dnaR-popover/dnaR-popover.directive");
const root_scope_provider_1 = require("./providers/root-scope.provider");
const dnaR_checkbox_component_1 = require("./components/dnaR-checkbox/dnaR-checkbox.component");
const resident_search_service_1 = require("./components/dnaR-search/services/resident-search.service");
const click_outside_directive_1 = require("./directives/click-outside-directive");
const nullToZero_pipe_1 = require("./pipes/nullToZero.pipe");
const i0 = require("@angular/core");
class SharedModule {
}
exports.SharedModule = SharedModule;
SharedModule.ɵmod = i0.ɵɵdefineNgModule({ type: SharedModule });
SharedModule.ɵinj = i0.ɵɵdefineInjector({ factory: function SharedModule_Factory(t) { return new (t || SharedModule)(); }, providers: [
        { provide: 'dataService', useFactory: (i) => { return i.get('data.service'); }, deps: ['$injector'] },
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        nullToZero_pipe_1.NullToZeroPipe,
        core_2.MatRipple,
        root_scope_provider_1.rootScopeProvider,
        resident_search_service_1.ResidentSearchService
    ], imports: [[
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            common_1.CommonModule,
            button_1.MatButtonModule
        ],
        forms_1.FormsModule,
        common_1.CommonModule] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SharedModule, { declarations: [show_hide_password_directive_1.ShowHidePasswordDirective,
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        nullToZero_pipe_1.NullToZeroPipe,
        dnaR_paginator_component_1.DnaRPaginator,
        dnaR_back_to_top_component_1.DnaRBackToTop,
        dnaR_popover_directive_1.DnaRPopoverDirective,
        dnaR_checkbox_component_1.DnaRCheckbox,
        click_outside_directive_1.ClickOutsideDirective], imports: [forms_1.FormsModule,
        forms_1.ReactiveFormsModule,
        common_1.CommonModule,
        button_1.MatButtonModule], exports: [forms_1.FormsModule,
        common_1.CommonModule,
        show_hide_password_directive_1.ShowHidePasswordDirective,
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        nullToZero_pipe_1.NullToZeroPipe,
        dnaR_paginator_component_1.DnaRPaginator,
        dnaR_back_to_top_component_1.DnaRBackToTop,
        dnaR_popover_directive_1.DnaRPopoverDirective,
        dnaR_checkbox_component_1.DnaRCheckbox,
        click_outside_directive_1.ClickOutsideDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SharedModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    common_1.CommonModule,
                    button_1.MatButtonModule
                ],
                providers: [
                    { provide: 'dataService', useFactory: (i) => { return i.get('data.service'); }, deps: ['$injector'] },
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    nullToZero_pipe_1.NullToZeroPipe,
                    core_2.MatRipple,
                    root_scope_provider_1.rootScopeProvider,
                    resident_search_service_1.ResidentSearchService
                ],
                declarations: [
                    show_hide_password_directive_1.ShowHidePasswordDirective,
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    nullToZero_pipe_1.NullToZeroPipe,
                    dnaR_paginator_component_1.DnaRPaginator,
                    dnaR_back_to_top_component_1.DnaRBackToTop,
                    dnaR_popover_directive_1.DnaRPopoverDirective,
                    dnaR_checkbox_component_1.DnaRCheckbox,
                    click_outside_directive_1.ClickOutsideDirective
                ],
                exports: [
                    forms_1.FormsModule,
                    common_1.CommonModule,
                    show_hide_password_directive_1.ShowHidePasswordDirective,
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    nullToZero_pipe_1.NullToZeroPipe,
                    dnaR_paginator_component_1.DnaRPaginator,
                    dnaR_back_to_top_component_1.DnaRBackToTop,
                    dnaR_popover_directive_1.DnaRPopoverDirective,
                    dnaR_checkbox_component_1.DnaRCheckbox,
                    click_outside_directive_1.ClickOutsideDirective
                ]
            }]
    }], null, null); })();
