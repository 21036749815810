"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_agreement_type_enum_1 = require("../enums/ilu-agreement-type.enum");
const i0 = require("@angular/core");
/**
 * Returns the display text for the specified @see IluAgreementType.
 *
 * @example
 * <span>{{agreementType | iluAgreementTypeText}}</span>
 */
class IluAgreementTypeTextPipe {
    /**
     * Returns the display text for the specified @see IluAgreementType.
     *
     * @param value The ILU agreement type value.
     * @returns The display text for the sepecified ILU agreement type if known, otherwise returns empty string.
     */
    transform(value) {
        if (value) {
            if (value === ilu_agreement_type_enum_1.IluAgreementType.CustomDmf) {
                return "Custom";
            }
            return ilu_agreement_type_enum_1.IluAgreementType[value];
        }
        return "";
    }
}
exports.IluAgreementTypeTextPipe = IluAgreementTypeTextPipe;
IluAgreementTypeTextPipe.ɵfac = function IluAgreementTypeTextPipe_Factory(t) { return new (t || IluAgreementTypeTextPipe)(); };
IluAgreementTypeTextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "iluAgreementTypeText", type: IluAgreementTypeTextPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluAgreementTypeTextPipe, [{
        type: core_1.Pipe,
        args: [{ name: "iluAgreementTypeText" }]
    }], null, null); })();
