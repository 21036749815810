"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_finanace_details_dto_1 = require("../dtos/ilu-finanace-details-dto");
const i0 = require("@angular/core");
/**
 * Returns the bill payer's combined name first & last name.
 *
 * @example
 * <span>{{billPlayer | billPayerName}}</span>
 */
class IluFinanceResidentBillPayerNamePipe {
    /**
     * Returns the bill payer's combined name first & last name.
     *
     * @param value The bill payer.
     * @returns The bill payer's combined name first & last name. Return empty string if bill payer is null or undefined.
     */
    transform(value) {
        if (value) {
            return ilu_finanace_details_dto_1.getBillPayerName(value);
        }
        return "";
    }
}
exports.IluFinanceResidentBillPayerNamePipe = IluFinanceResidentBillPayerNamePipe;
IluFinanceResidentBillPayerNamePipe.ɵfac = function IluFinanceResidentBillPayerNamePipe_Factory(t) { return new (t || IluFinanceResidentBillPayerNamePipe)(); };
IluFinanceResidentBillPayerNamePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "billPayerName", type: IluFinanceResidentBillPayerNamePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluFinanceResidentBillPayerNamePipe, [{
        type: core_1.Pipe,
        args: [{ name: "billPayerName" }]
    }], null, null); })();
