"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const independent_living_allocation_dmf_settlement_cost_dto_1 = require("../../dtos/independent-living-allocation-dmf-settlement-cost-dto");
const isNum_1 = require("../../../common/validators/isNum");
const i0 = require("@angular/core");
/**
 * Returns the formatted settlement cost total.
 *
 * @example
 * <span>{{settlementCost | settlementCostTotal: ingoingContributions: outgoingUnitPrice}}</span>
 */
class SettlementCostTotalPipe {
    /**
     * Initializes a new instance of the @see SettlementCostTotalPipe class.
     *
     * @param local The locale code for the locale formatting rules to use.
     */
    constructor(locale) {
        this.locale = locale;
    }
    /**
     * Returns the formatted total for the given settlement cost.
     *
     * @param settlementCost Settlement cost details.
     * @param ingoingContributions Collection of ingoing contributions. Source data is a collection and you can't nest pipes to sum the collection externally of this pipe.
     * @param outgoingUnitPrice DMF unit outgoing (sale) price. OutgoingUnitPrice isn't always known. If outgoingUnitPrice is null, then the calculations will use ingoingContributions instead.
     * @returns The caculated settlement cost total formatted as currency.
     */
    transform(settlementCost, incomingUnitPrice, outgoingUnitPrice) {
        if (settlementCost === null || settlementCost === void 0 ? void 0 : settlementCost.settlementCostType) {
            const _outgoingUnitPrice = (outgoingUnitPrice !== undefined && outgoingUnitPrice !== null && isNum_1.isNum(outgoingUnitPrice)) ? Number(outgoingUnitPrice) : null;
            const total = independent_living_allocation_dmf_settlement_cost_dto_1.settlementCostTotal(settlementCost, incomingUnitPrice, _outgoingUnitPrice);
            if (total !== null && total !== undefined) {
                return `$${common_1.formatNumber(total, this.locale, "1.2-4")}`;
            }
            return "";
        }
        return "";
    }
}
exports.SettlementCostTotalPipe = SettlementCostTotalPipe;
SettlementCostTotalPipe.ɵfac = function SettlementCostTotalPipe_Factory(t) { return new (t || SettlementCostTotalPipe)(i0.ɵɵdirectiveInject(core_1.LOCALE_ID)); };
SettlementCostTotalPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "settlementCostTotal", type: SettlementCostTotalPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SettlementCostTotalPipe, [{
        type: core_1.Pipe,
        args: [{ name: "settlementCostTotal" }]
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: [core_1.LOCALE_ID]
            }] }]; }, null); })();
