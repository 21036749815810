"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const value_with_effective_date_component_1 = require("./value-with-effective-date.component");
const icon_1 = require("@angular/material/icon");
const dialog_1 = require("@angular/material/dialog");
const tooltip_1 = require("@angular/material/tooltip");
const button_1 = require("@angular/material/button");
const dnaR_datePicker_module_1 = require("./../dnaR-datePicker/dnaR-datePicker.module");
const dnaR_input_module_1 = require("./../dnaR-input/dnaR-input.module");
const common_1 = require("@angular/common");
const platform_browser_1 = require("@angular/platform-browser");
const shared_module_1 = require("../../shared.module");
const i0 = require("@angular/core");
class ValueWithEffectiveDateDialogModule {
    static forRoot() {
        return {
            ngModule: ValueWithEffectiveDateDialogModule,
        };
    }
}
exports.ValueWithEffectiveDateDialogModule = ValueWithEffectiveDateDialogModule;
ValueWithEffectiveDateDialogModule.ɵmod = i0.ɵɵdefineNgModule({ type: ValueWithEffectiveDateDialogModule });
ValueWithEffectiveDateDialogModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ValueWithEffectiveDateDialogModule_Factory(t) { return new (t || ValueWithEffectiveDateDialogModule)(); }, imports: [[
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            icon_1.MatIconModule,
            dialog_1.MatDialogModule,
            tooltip_1.MatTooltipModule,
            button_1.MatButtonModule,
            dnaR_datePicker_module_1.DnaRDatePickerModule,
            dnaR_input_module_1.DnaRInputModule,
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            common_1.CommonModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ValueWithEffectiveDateDialogModule, { declarations: [value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent], imports: [forms_1.FormsModule,
        forms_1.ReactiveFormsModule,
        icon_1.MatIconModule,
        dialog_1.MatDialogModule,
        tooltip_1.MatTooltipModule,
        button_1.MatButtonModule,
        dnaR_datePicker_module_1.DnaRDatePickerModule,
        dnaR_input_module_1.DnaRInputModule,
        shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        common_1.CommonModule], exports: [value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ValueWithEffectiveDateDialogModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    icon_1.MatIconModule,
                    dialog_1.MatDialogModule,
                    tooltip_1.MatTooltipModule,
                    button_1.MatButtonModule,
                    dnaR_datePicker_module_1.DnaRDatePickerModule,
                    dnaR_input_module_1.DnaRInputModule,
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    common_1.CommonModule
                ],
                declarations: [value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent],
                exports: [value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent],
            }]
    }], null, null); })();
