"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Possible value types.
 */
var ValueWithEffectiveDateDialogComponentValueType;
(function (ValueWithEffectiveDateDialogComponentValueType) {
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Percentage"] = 0] = "Percentage";
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Integer"] = 1] = "Integer";
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Currency"] = 2] = "Currency";
})(ValueWithEffectiveDateDialogComponentValueType = exports.ValueWithEffectiveDateDialogComponentValueType || (exports.ValueWithEffectiveDateDialogComponentValueType = {}));
