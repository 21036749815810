"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillingRecurrenceInterval;
(function (BillingRecurrenceInterval) {
    BillingRecurrenceInterval["Daily"] = "Daily";
    BillingRecurrenceInterval["Weekly"] = "Weekly";
    BillingRecurrenceInterval["Fortnightly"] = "Fortnightly";
    BillingRecurrenceInterval["Monthly"] = "Monthly";
    BillingRecurrenceInterval["Yearly"] = "Yearly";
})(BillingRecurrenceInterval = exports.BillingRecurrenceInterval || (exports.BillingRecurrenceInterval = {}));
