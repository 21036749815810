"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./region-dto");
require("./values-with-effective-date-dto");
class FacilityDto {
}
exports.FacilityDto = FacilityDto;
class FacilityLocationDto {
}
exports.FacilityLocationDto = FacilityLocationDto;
