"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const prospect_dto_1 = require("../dtos/prospect-dto");
const i0 = require("@angular/core");
/**
 * Returns the prospect's combined name first & last name.
 *
 * @example
 * <span>{{prospect | prospectName}}</span>
 */
class ProspectNamePipe {
    /**
     * Returns the prospect's combined name first & last name.
     *
     * @param value The prospect.
     * @returns The prospect's combined name first & last name. Return empty string if the prospect is null or undefined.
     */
    transform(value) {
        if (value) {
            return prospect_dto_1.getProspectName(value);
        }
        return "";
    }
}
exports.ProspectNamePipe = ProspectNamePipe;
ProspectNamePipe.ɵfac = function ProspectNamePipe_Factory(t) { return new (t || ProspectNamePipe)(); };
ProspectNamePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "prospectName", type: ProspectNamePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ProspectNamePipe, [{
        type: core_1.Pipe,
        args: [{ name: "prospectName" }]
    }], null, null); })();
