"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const drag_drop_1 = require("@angular/cdk/drag-drop");
const dnaR_snack_bar_service_1 = require("../../../common/components/dnaR-snack-bar/dnaR-snack-bar.service");
const independent_living_service_1 = require("../../services/independent-living.service");
const lead_card_1 = require("../../models/lead-card");
const overlay_1 = require("@angular/cdk/overlay");
const simple_enquiry_dto_1 = require("../../dtos/simple-enquiry-dto");
const _ = require("underscore");
require("../../../common/components/dnaR-paginator/dnaR-paginator.component");
const i0 = require("@angular/core");
const i1 = require("../../services/independent-living.service");
const i2 = require("../../../common/components/dnaR-snack-bar/dnaR-snack-bar.service");
const i3 = require("@angular/cdk/overlay");
const i4 = require("@angular/common");
const i5 = require("@angular/cdk/drag-drop");
const i6 = require("../../../common/components/dnaR-paginator/dnaR-paginator.component");
const i7 = require("./lead-card.component");
function ArchivedLeadsComponent_div_6_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵlistener("cdkDropListDropped", function ArchivedLeadsComponent_div_6_div_3_Template_div_cdkDropListDropped_0_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(2); return ctx_r4.dropListDropped($event); });
    i0.ɵɵelementStart(1, "div", 9);
    i0.ɵɵlistener("cdkDragMoved", function ArchivedLeadsComponent_div_6_div_3_Template_div_cdkDragMoved_1_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r6 = i0.ɵɵnextContext(2); return ctx_r6.dragMoved($event); });
    i0.ɵɵelementStart(2, "lead-card", 10);
    i0.ɵɵlistener("onDelete", function ArchivedLeadsComponent_div_6_div_3_Template_lead_card_onDelete_2_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r7 = i0.ɵɵnextContext(2); return ctx_r7.handleOnDelete($event); })("onReinstateLead", function ArchivedLeadsComponent_div_6_div_3_Template_lead_card_onReinstateLead_2_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r8 = i0.ɵɵnextContext(2); return ctx_r8.handleOnReinstateLead($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const leadCard_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("cdkDropListEnterPredicate", ctx_r2.dropListEnterPredicate);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("leadCard", leadCard_r3)("canDeleteLeads", ctx_r2.canDeleteLeads)("isMuted", leadCard_r3.isMuted);
} }
function ArchivedLeadsComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵlistener("cdkDropListDropped", function ArchivedLeadsComponent_div_6_Template_div_cdkDropListDropped_2_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.dropListDropped($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ArchivedLeadsComponent_div_6_div_3_Template, 3, 4, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "dnaR-paginator", 8);
    i0.ɵɵlistener("page", function ArchivedLeadsComponent_div_6_Template_dnaR_paginator_page_4_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.handlePageEvent($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("cdkDropListEnterPredicate", ctx_r0.dropListEnterPredicate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.cards);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("length", ctx_r0.totalNumberOfCards)("pageSize", ctx_r0.pageSize)("pageSizeOptions", ctx_r0.pageSizeOptions)("pageIndex", ctx_r0.pageIndex)("itemsDisplayLabel", "Items");
} }
function ArchivedLeadsComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵelement(1, "div", 12);
    i0.ɵɵelementStart(2, "h3");
    i0.ɵɵtext(3, "No archived leads");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
class ArchivedLeadsComponent {
    /**
     * Initializes a new instance of the MobilityRequirementsComponent class.
     *
     * @param independentLivingService - The @see IndependentLivingService service.
     * @param snackBar - The @see DnaRSnackBarService service.
     * @param authenticationService - The authentication service.
     * @param viewportRuler - The @see ViewportRuler service.
     */
    constructor(independentLivingService, snackBarService, authenticationService, viewportRuler) {
        this.independentLivingService = independentLivingService;
        this.snackBarService = snackBarService;
        this.authenticationService = authenticationService;
        this.viewportRuler = viewportRuler;
        /**
         * The card pagination page index.
         */
        this.pageIndex = 0;
        /**
         * The card pagination page size.
         */
        this.pageSize = 50;
        /**
         * The card pagination page size options
         */
        this.pageSizeOptions = [50, 100, 'All'];
        /**
         * The total number of cards
         */
        this.totalNumberOfCards = 0;
        /**
         * The value of the delete leads permission (leadsDeleteLeads.CanAction).
         */
        this.canDeleteLeads = false;
        /**
         * Request the user's current canDeleteLeads permission.
         */
        this.setCanDeleteLeads = () => {
            this.authenticationService.userHasPermissionDeferred((x) => {
                return x.leadsDeleteLeads.canAction;
            }).then(() => {
                this.canDeleteLeads = true;
            }, () => {
                this.canDeleteLeads = false;
            });
        };
        this.target = null;
        this.source = null;
        this.activeContainer = null;
        this.dropListEnterPredicate = (drag, drop) => {
            if (drop == this.placeholder)
                return true;
            if (drop != this.activeContainer)
                return false;
            let phElement = this.placeholder.element.nativeElement;
            let sourceElement = drag.dropContainer.element.nativeElement;
            let dropElement = drop.element.nativeElement;
            let dragIndex = _.indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
            let dropIndex = _.indexOf(dropElement.parentElement.children, dropElement);
            if (!this.source) {
                this.sourceIndex = dragIndex;
                this.source = drag.dropContainer;
                phElement.style.width = sourceElement.clientWidth + 'px';
                phElement.style.height = sourceElement.clientHeight + 'px';
                sourceElement.parentElement.removeChild(sourceElement);
            }
            this.targetIndex = dropIndex;
            this.target = drop;
            phElement.style.display = '';
            dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
                ? dropElement.nextSibling : dropElement));
            this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
            return false;
        };
        this.cards = [
            { cardType: lead_card_1.CardType.Loading, enquiry: null, isMuted: false },
            { cardType: lead_card_1.CardType.Loading, enquiry: null, isMuted: false },
            { cardType: lead_card_1.CardType.Loading, enquiry: null, isMuted: false },
            { cardType: lead_card_1.CardType.Loading, enquiry: null, isMuted: false },
            { cardType: lead_card_1.CardType.Loading, enquiry: null, isMuted: false }
        ];
    }
    /**
     * @inheritdoc
     */
    ngOnInit() {
        this.loadArchivedLeads();
        this.setCanDeleteLeads();
    }
    /**
     * Load a page of cards.
     */
    loadArchivedLeads() {
        let skip = null;
        let take = null;
        // Calculate pagination values only if isn't a number (ie 'All')
        if (!isNaN(this.pageSize)) {
            skip = this.pageSize * this.pageIndex;
            take = Number(this.pageSize);
        }
        this.independentLivingService.getArchivedLeads(skip, take).then(result => {
            this.cards = result.result.map(l => new lead_card_1.LeadCard(l));
            this.totalNumberOfCards = result.totalCount;
        }, () => {
            this.snackBarService.failure("Unexpected error while loading enquiries");
        });
    }
    /**
     * Handing the page event
     *
     * @param e The page event parameters.
     */
    handlePageEvent(e) {
        this.totalNumberOfCards = e.length;
        this.pageSize = e.pageSize;
        this.pageIndex = e.pageIndex;
        this.loadArchivedLeads();
    }
    /**
     * After the card has been dropped updates the card's possition in the local collection and then notify the system of the cards position in the order.
     *
     * @param sourceIndex The index of the card that was dragged.
     * @param targetIndex The new index of the card that was dragged.
     *
     * @description If sourceIndex and targetIndex are the same then card wasn't moved and not action is necessary.
     * Refactored out of the multidimenstional drag and drop code to keep that general-ish.
     * Notifying the API of the re-ordering is deliberately done in the background to maintain the illusion of it working like magic :)
     */
    afterDropped(sourceIndex, targetIndex) {
        var _a;
        if (sourceIndex != targetIndex) {
            drag_drop_1.moveItemInArray(this.cards, sourceIndex, targetIndex);
            let beforeCard = this.cards[targetIndex - 1];
            let targetcard = this.cards[targetIndex];
            this.independentLivingService.updateArchivedCardOrderNumber(targetcard.enquiry.enquiryId, (_a = beforeCard === null || beforeCard === void 0 ? void 0 : beforeCard.enquiry.enquiryId) !== null && _a !== void 0 ? _a : null).catch(error => console.log(error));
        }
    }
    /**
     * Delete enquiry in response to user selecting the card's delete action.
     *
     * @param leadCard The lead card to be deleted.
     */
    handleOnDelete(leadCard) {
        let names = simple_enquiry_dto_1.getEnquiryNames(leadCard.enquiry);
        leadCard.isMuted = true;
        this.independentLivingService.deleteEnquiry(leadCard.enquiry.enquiryId)
            .then(result => {
            leadCard.isMuted = false;
            this.snackBarService.success(names, 'deleted');
            const index = _.indexOf(this.cards, leadCard);
            this.cards.splice(index, 1);
        }, () => {
            leadCard.isMuted = false;
            this.snackBarService.failure('Unexpected error occurred while deleting an enquiry record.');
        });
    }
    /**
     * Re-instate enquiry from archive in response to user selecting the card's reinstate lead action.
     *
     * @param leadCard The lead card to be re-instated from archive.
     */
    handleOnReinstateLead(leadCard) {
        leadCard.isMuted = true;
        this.independentLivingService.reinstateFromArchive(leadCard.enquiry.enquiryId)
            .then(result => {
            leadCard.isMuted = false;
            const names = simple_enquiry_dto_1.getEnquiryNames(leadCard.enquiry);
            this.snackBarService.success(names, 'reinstated');
            const index = _.indexOf(this.cards, leadCard);
            this.cards.splice(index, 1);
        }, () => {
            leadCard.isMuted = false;
            this.snackBarService.failure('Unexpected error occurred while reinstating an enquiry record.');
        });
    }
    /**
     * @inheritdoc
     */
    ngAfterViewInit() {
        let phElement = this.placeholder.element.nativeElement;
        phElement.style.display = 'none';
        phElement.parentElement.removeChild(phElement);
    }
    dragMoved(e) {
        let point = this.getPointerPositionOnPage(e.event);
        this.listGroup._items.forEach(dropList => {
            if (this.__isInsideDropListClientRect(dropList, point.x, point.y)) {
                this.activeContainer = dropList;
                return;
            }
        });
    }
    dropListDropped(event) {
        if (!this.target)
            return;
        let phElement = this.placeholder.element.nativeElement;
        let parent = phElement.parentElement;
        phElement.style.display = 'none';
        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);
        this.target = null;
        this.source = null;
        // code below here
        this.afterDropped(this.sourceIndex, this.targetIndex);
    }
    /** Determines the point of the page that was touched by the user. */
    getPointerPositionOnPage(event) {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = this.__isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();
        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
    }
    /** Determines whether an event is a touch event. */
    __isTouchEvent(event) {
        return event.type.startsWith('touch');
    }
    __isInsideDropListClientRect(dropList, x, y) {
        const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
        return y >= top && y <= bottom && x >= left && x <= right;
    }
}
exports.ArchivedLeadsComponent = ArchivedLeadsComponent;
ArchivedLeadsComponent.ɵfac = function ArchivedLeadsComponent_Factory(t) { return new (t || ArchivedLeadsComponent)(i0.ɵɵdirectiveInject(i1.IndependentLivingService), i0.ɵɵdirectiveInject(i2.DnaRSnackBarService), i0.ɵɵdirectiveInject('authentication.service'), i0.ɵɵdirectiveInject(i3.ViewportRuler)); };
ArchivedLeadsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ArchivedLeadsComponent, selectors: [["ng-component"]], viewQuery: function ArchivedLeadsComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(drag_drop_1.CdkDropListGroup, true);
        i0.ɵɵviewQuery(drag_drop_1.CdkDropList, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listGroup = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.placeholder = _t.first);
    } }, decls: 8, vars: 2, consts: [[1, "ilu-archived-leads", "dna-r"], [1, "page-header"], ["href", "/#/independentLiving/viewEnquiries", 1, "btn", "btn-small", "btn-secondary"], [4, "ngIf"], ["class", "no-records", 4, "ngIf"], ["cdkDropListGroup", "", 1, "card-container"], ["cdkDropList", "", 3, "cdkDropListEnterPredicate", "cdkDropListDropped"], ["cdkDropList", "", 3, "cdkDropListEnterPredicate", "cdkDropListDropped", 4, "ngFor", "ngForOf"], [3, "length", "pageSize", "pageSizeOptions", "pageIndex", "itemsDisplayLabel", "page"], ["cdkDrag", "", 3, "cdkDragMoved"], [3, "leadCard", "canDeleteLeads", "isMuted", "onDelete", "onReinstateLead"], [1, "no-records"], [1, "image"]], template: function ArchivedLeadsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "h1");
        i0.ɵɵtext(3, "Archived leads");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "a", 2);
        i0.ɵɵtext(5, "Back to leads");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, ArchivedLeadsComponent_div_6_Template, 5, 7, "div", 3);
        i0.ɵɵtemplate(7, ArchivedLeadsComponent_div_7_Template, 4, 0, "div", 4);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngIf", (ctx.cards == null ? null : ctx.cards.length) > 0);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", (ctx.cards == null ? null : ctx.cards.length) === 0);
    } }, directives: [i4.NgIf, i5.CdkDropListGroup, i5.CdkDropList, i4.NgForOf, i6.DnaRPaginator, i5.CdkDrag, i7.LeadCardComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ArchivedLeadsComponent, [{
        type: core_1.Component,
        args: [{
                templateUrl: './archived-leads.component.html'
            }]
    }], function () { return [{ type: i1.IndependentLivingService }, { type: i2.DnaRSnackBarService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['authentication.service']
            }] }, { type: i3.ViewportRuler }]; }, { listGroup: [{
            type: core_1.ViewChild,
            args: [drag_drop_1.CdkDropListGroup]
        }], placeholder: [{
            type: core_1.ViewChild,
            args: [drag_drop_1.CdkDropList]
        }] }); })();
