"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../enums/ilu-dmf-calculated-on.enum");
require("../enums/ilu-dmf-commences-on.enum");
require("../enums/ilu-dmf-post-timing.enum");
require("../enums/ilu-dmf-rate-type.enum");
require("./independent-living-allocation-dmf-accrual-frequency-dto");
require("./independent-living-allocation-dmf-period-rate-dto");
require("./independent-living-allocation-dmf-post-frequency-dto");
require("./independent-living-allocation-dmf-settlement-cost-dto");
require("./independent-living-allocation-dmf-totals-dto");
class IndependentLivingAllocationDmfDto {
}
exports.IndependentLivingAllocationDmfDto = IndependentLivingAllocationDmfDto;
