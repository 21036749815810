"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_dmf_post_timing_enum_1 = require("../enums/ilu-dmf-post-timing.enum");
const i0 = require("@angular/core");
/**
 * Returns the display text for the specified @see IluDmfPostTiming.
 *
 * @example
 * <span>{{postTiming | iluDmfPostTimingText}}</span>
 */
class IluDmfPostTimingTextPipe {
    /**
     * Returns the display text for the specified @see IluDmfPostTiming.
     *
     * @param value The DMF post timing on value.
     * @returns The display text for the sepecified DMF post timing on if known, otherwise returns empty string.
     */
    transform(value) {
        if (value) {
            return ilu_dmf_post_timing_enum_1.IluDmfPostTiming[value];
        }
        return "";
    }
}
exports.IluDmfPostTimingTextPipe = IluDmfPostTimingTextPipe;
IluDmfPostTimingTextPipe.ɵfac = function IluDmfPostTimingTextPipe_Factory(t) { return new (t || IluDmfPostTimingTextPipe)(); };
IluDmfPostTimingTextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "iluDmfPostTimingText", type: IluDmfPostTimingTextPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluDmfPostTimingTextPipe, [{
        type: core_1.Pipe,
        args: [{ name: "iluDmfPostTimingText" }]
    }], null, null); })();
