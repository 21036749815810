"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_finanace_details_dto_1 = require("../dtos/ilu-finanace-details-dto");
require("../dtos/values-with-effective-date-dto");
const i0 = require("@angular/core");
/**
 * Returns @see ValuesWithEffectiveDateDto for the provided optional service's current rate (based on the current time).
 *
 * @example
 * <span>{{optionalService | optionalServiceCurrentRate | valuesWithEffectiveDateValue }}</span>
 */
class OptionalServiceCurrentRatePipe {
    /**
     * Returns @see ValuesWithEffectiveDateDto for the provided optional service's current rate (based on the current time).
     *
     * @param value The option service.
     * @returns The @see ValuesWithEffectiveDateDto for the provided optional service's current rate. Returns null if the optional service has no current rate or no rates.
     */
    transform(value) {
        if (value && value.rates && value.rates.length > 0) {
            return ilu_finanace_details_dto_1.getOptionalServiceCurrentRate(value);
        }
        return null;
    }
}
exports.OptionalServiceCurrentRatePipe = OptionalServiceCurrentRatePipe;
OptionalServiceCurrentRatePipe.ɵfac = function OptionalServiceCurrentRatePipe_Factory(t) { return new (t || OptionalServiceCurrentRatePipe)(); };
OptionalServiceCurrentRatePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "optionalServiceCurrentRate", type: OptionalServiceCurrentRatePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OptionalServiceCurrentRatePipe, [{
        type: core_1.Pipe,
        args: [{ name: "optionalServiceCurrentRate" }]
    }], null, null); })();
