"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
const i1 = require("@angular/material/tooltip");
/**
 * This is a throw away component just to embed the Angular Material Tooltip in a AngularJS template.
 */
class AddNewProspectComponent {
}
exports.AddNewProspectComponent = AddNewProspectComponent;
AddNewProspectComponent.ɵfac = function AddNewProspectComponent_Factory(t) { return new (t || AddNewProspectComponent)(); };
AddNewProspectComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AddNewProspectComponent, selectors: [["add-new-prospect"]], decls: 2, vars: 0, consts: [["ng-click", "vm.addResident()", "data-automation", "AddPersonButton", "matTooltip", "Add new prospect", "matTooltipClass", "mat-tooltip", 1, "dna-r", "btn", "btn-clear", "view-enquiry-add-person"], [1, "icon", "icon-add-outline"]], template: function AddNewProspectComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "a", 0);
        i0.ɵɵelement(1, "span", 1);
        i0.ɵɵelementEnd();
    } }, directives: [i1.MatTooltip], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AddNewProspectComponent, [{
        type: core_1.Component,
        args: [{
                selector: "add-new-prospect",
                templateUrl: "./add-new-prospect.component.html"
            }]
    }], null, null); })();
