"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dnaR_alert_component_1 = require("./dnaR-alert.component");
const common_1 = require("@angular/common");
const animations_1 = require("@angular/platform-browser/animations");
const i0 = require("@angular/core");
class DnaRAlertModule {
    static forRoot() {
        return {
            ngModule: DnaRAlertModule,
        };
    }
}
exports.DnaRAlertModule = DnaRAlertModule;
DnaRAlertModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRAlertModule });
DnaRAlertModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRAlertModule_Factory(t) { return new (t || DnaRAlertModule)(); }, imports: [[common_1.CommonModule, animations_1.BrowserAnimationsModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRAlertModule, { declarations: [dnaR_alert_component_1.DnaRAlertComponent], imports: [common_1.CommonModule, animations_1.BrowserAnimationsModule], exports: [dnaR_alert_component_1.DnaRAlertComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRAlertModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [common_1.CommonModule, animations_1.BrowserAnimationsModule],
                declarations: [dnaR_alert_component_1.DnaRAlertComponent],
                exports: [dnaR_alert_component_1.DnaRAlertComponent],
                schemas: [core_1.CUSTOM_ELEMENTS_SCHEMA],
            }]
    }], null, null); })();
