"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
/**
 * Returns the elements in the items array that match the specified accumulatePeriodRates value.
 *
 * @example
 * *ngFor="let item of periodRateTypes | accumulatePeriodRateFilter: accumulatePeriodRates"
 */
class AccumulatePeriodRateFilterPipe {
    /**
     * Returns the elements in the items array that match the specified accumulatePeriodRates value.
     *
     * @param items The array of items.
     * @param accumulatePeriodRates The accumulatePeriodRates value.
     * @returns The elements in the items array that match the specified accumulatePeriodRates value.
     */
    transform(items, accumulatePeriodRates) {
        if (!items) {
            return items;
        }
        return items.filter((e) => e["accumulatePeriodRates"] === accumulatePeriodRates);
    }
}
exports.AccumulatePeriodRateFilterPipe = AccumulatePeriodRateFilterPipe;
AccumulatePeriodRateFilterPipe.ɵfac = function AccumulatePeriodRateFilterPipe_Factory(t) { return new (t || AccumulatePeriodRateFilterPipe)(); };
AccumulatePeriodRateFilterPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "accumulatePeriodRateFilter", type: AccumulatePeriodRateFilterPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AccumulatePeriodRateFilterPipe, [{
        type: core_1.Pipe,
        args: [{ name: "accumulatePeriodRateFilter" }]
    }], null, null); })();
