"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillPayerType;
(function (BillPayerType) {
    BillPayerType["Resident"] = "Resident";
    BillPayerType["PrimaryContact"] = "PrimaryContact";
    BillPayerType["SecondaryContact"] = "SecondaryContact";
})(BillPayerType = exports.BillPayerType || (exports.BillPayerType = {}));
/**
 * Returns a string of the provided bill payer type.
 *
 * @param billPayerType The bill payer type.
 * @returns A string of the provided bill payer type.
 */
function billPayerTypeToString(billPayerType) {
    switch (billPayerType) {
        case BillPayerType.Resident:
            return "resident";
        case BillPayerType.PrimaryContact:
            return "primaryContact";
        case BillPayerType.SecondaryContact:
            return "secondaryContact";
        default:
            return undefined;
    }
}
exports.billPayerTypeToString = billPayerTypeToString;
