"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const forms_1 = require("@angular/forms");
const period_rate_totals_1 = require("../models/period-rate-totals");
/**
 * Validator that requires the DMF rate schedule to have a maximum total percentage of 100%.
 *
 * Assumes that control is a @see FormArray.
 *
 * @returns An error map with `maxTotalPercentage` property if the maximum total precentage validation check failes, otherwise `null`.
 */
function maxTotalPercentageValidator(control) {
    if (control instanceof forms_1.FormArray) {
        const totals = period_rate_totals_1.periodRateTotals(control.value);
        if (totals.percentage && totals.percentage > 100) {
            return { maxTotalPercentage: true };
        }
    }
    return null;
}
exports.maxTotalPercentageValidator = maxTotalPercentageValidator;
