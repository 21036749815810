"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ilu_dmf_rate_type_enum_1 = require("../enums/ilu-dmf-rate-type.enum");
require("./independent-living-allocation-dmf-settlement-cost-type-dto");
class IndependentLivingAllocationDmfSettlementCostDto {
}
exports.IndependentLivingAllocationDmfSettlementCostDto = IndependentLivingAllocationDmfSettlementCostDto;
function settlementCostTotal(settlementCost, incomingUnitPrice, outgoingUnitPrice) {
    var _a, _b, _c, _d, _e, _f;
    if (((_a = settlementCost.settlementCostType) === null || _a === void 0 ? void 0 : _a.rateType) === ilu_dmf_rate_type_enum_1.IluDmfRateType.Dollar) {
        return settlementCost.settlementCostValue;
    }
    if (((_b = settlementCost.settlementCostType) === null || _b === void 0 ? void 0 : _b.rateType) === ilu_dmf_rate_type_enum_1.IluDmfRateType.Percentage) {
        // Cost is percentage of the ingoing contributions (settlementCostType.id = 2)
        if (((_c = settlementCost.settlementCostType) === null || _c === void 0 ? void 0 : _c.id) === 2) {
            return ((incomingUnitPrice !== null && incomingUnitPrice !== void 0 ? incomingUnitPrice : 0) * ((_d = settlementCost.settlementCostValue) !== null && _d !== void 0 ? _d : 0)) / 100;
        }
        // should outgoingUnitPrice be ignored in favour of ingoingContributionTotal when outgoingUnitPrice is 0???
        // Cost is percentage of the outgoing unit price (settlementCostType.id = 1)
        return (((_e = (outgoingUnitPrice ? outgoingUnitPrice : incomingUnitPrice)) !== null && _e !== void 0 ? _e : 0) * ((_f = settlementCost.settlementCostValue) !== null && _f !== void 0 ? _f : 0)) / 100;
    }
    return null;
}
exports.settlementCostTotal = settlementCostTotal;
