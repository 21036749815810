"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ilu_dmf_calculated_on_enum_1 = require("../enums/ilu-dmf-calculated-on.enum");
const i0 = require("@angular/core");
/**
 * Returns the display text for the specified @see IluDmfCalculatedOn.
 *
 * @example
 * <span>{{calculatedOn | iluDmfCalculatedOnText}}</span>
 */
class IluDmfCalculatedOnTextPipe {
    /**
     * Returns the display text for the specified @see IluDmfCalculatedOn.
     *
     * @param value The DMF calculated on value.
     * @returns The display text for the sepecified DMF calculated on if known, otherwise returns empty string.
     */
    transform(value) {
        if (value) {
            if (value === ilu_dmf_calculated_on_enum_1.IluDmfCalculatedOn.IngoingUnitPrice) {
                return "Ingoing unit price";
            }
            if (value === ilu_dmf_calculated_on_enum_1.IluDmfCalculatedOn.OutgoingUnitPrice) {
                return "Outgoing unit price";
            }
        }
        return "";
    }
}
exports.IluDmfCalculatedOnTextPipe = IluDmfCalculatedOnTextPipe;
IluDmfCalculatedOnTextPipe.ɵfac = function IluDmfCalculatedOnTextPipe_Factory(t) { return new (t || IluDmfCalculatedOnTextPipe)(); };
IluDmfCalculatedOnTextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "iluDmfCalculatedOnText", type: IluDmfCalculatedOnTextPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluDmfCalculatedOnTextPipe, [{
        type: core_1.Pipe,
        args: [{ name: "iluDmfCalculatedOnText" }]
    }], null, null); })();
