"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./accomodation-dto");
require("./ilu-agreement-template-dto");
require("./independent-living-allocation-dmf-dto");
require("./values-with-effective-date-dto");
require("../enums/bill-payer-type.enum");
class IluFinanceDetailsDto {
}
exports.IluFinanceDetailsDto = IluFinanceDetailsDto;
class IluFinanceResidencyDto {
}
exports.IluFinanceResidencyDto = IluFinanceResidencyDto;
class IluFinanceResidentDto {
}
exports.IluFinanceResidentDto = IluFinanceResidentDto;
class IluFinanceResidentBillPayerDto {
}
exports.IluFinanceResidentBillPayerDto = IluFinanceResidentBillPayerDto;
class IluFinanceResidencyFinanceOptionalServiceDto {
}
exports.IluFinanceResidencyFinanceOptionalServiceDto = IluFinanceResidencyFinanceOptionalServiceDto;
class IluFinanceOptionalServiceDto {
}
exports.IluFinanceOptionalServiceDto = IluFinanceOptionalServiceDto;
class IluFinanceAccommodationDetailsDto {
}
exports.IluFinanceAccommodationDetailsDto = IluFinanceAccommodationDetailsDto;
class IndependentLivingAllocationIngoingContributionDto {
}
exports.IndependentLivingAllocationIngoingContributionDto = IndependentLivingAllocationIngoingContributionDto;
/**
 * Returns a string of the provided resident's name.
 *
 * @param resident The resident.
 * @param lastNameAllCaps If the resident last name should be in all caps.
 * @returns The resident's name.
 */
function getResidentName(resident, lastNameAllCaps = false) {
    if (!lastNameAllCaps) {
        return `${resident.firstName} ${resident.lastName}`;
    }
    else {
        return `${resident.firstName} ${resident.lastName.toUpperCase()}`;
    }
}
exports.getResidentName = getResidentName;
/**
 * Returns @see ValuesWithEffectiveDateDto for the provided optional service's current rate (based on the current time).
 *
 * @param optionalService The optional service.
 * @returns The current rate. Returns null if the optional service has no current rate or no rates.
 */
function getOptionalServiceCurrentRate(optionalService) {
    var _a;
    const now = new Date();
    const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    return (_a = optionalService.rates.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()).find((o) => new Date(o.startDate) <= nowUtc)) !== null && _a !== void 0 ? _a : null;
}
exports.getOptionalServiceCurrentRate = getOptionalServiceCurrentRate;
/**
 * Returns a string of the provided bill payer's name.
 *
 * @param billPayer The bill payer.
 * @returns The resident's name.
 */
function getBillPayerName(billPayer) {
    return `${billPayer.firstName} ${billPayer.lastName}`;
}
exports.getBillPayerName = getBillPayerName;
