"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dialog_1 = require("@angular/material/dialog");
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
const i1 = require("@angular/material/dialog");
const i2 = require("@angular/material/button");
const i3 = require("@angular/material/tooltip");
const i4 = require("@angular/material/icon");
const i5 = require("../../common/components/dnaR-search/dnaR-search.component");
class AddToUnitModalComponent {
    constructor(dialogRef) {
        this.dialogRef = dialogRef;
        this.formSubmitted = new rxjs_1.Subject();
    }
    cancel() {
        this.hideDialogEvent();
    }
    submit() {
        this.formSubmitted.next(true);
    }
}
exports.AddToUnitModalComponent = AddToUnitModalComponent;
AddToUnitModalComponent.ɵfac = function AddToUnitModalComponent_Factory(t) { return new (t || AddToUnitModalComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef)); };
AddToUnitModalComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AddToUnitModalComponent, selectors: [["add-to-unit-modal"]], inputs: { hideDialogEvent: "hideDialogEvent" }, decls: 21, vars: 1, consts: [[1, "modal-dialog", "angular-dialog"], [1, "body"], ["mat-dialog-title", "", 1, "modal-header", "modal-header-action"], [1, "col-xs-11", "noSidePadding"], [1, "modal-title", "title"], [1, "col-xs-1", "noSidePadding"], ["mat-icon-button", "", "matTooltip", "Cancel", "matTooltipClass", "mat-tooltip", "aria-label", "close dialog", 1, "close-modal", "pull-right", 3, "click"], [1, "icon", "icon-close-fill", "close-modal-icon"], [1, "modal-body"], [1, "required"], ["showClearSelectedItems", "true", "placeholder", "Search prospect", "isRequired", "true", "requiredError", "Resident required", "maxResults", "10", 3, "formSubmittedObservable"], [1, "modal-footer", "col-xs-12"], [1, "btn-group-md"], ["type", "button", 1, "btn", "btn-warning", "modal-warning-button", 3, "click"], ["type", "submit", 1, "btn", "btn-primary", "modal-action-button", 3, "click"]], template: function AddToUnitModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "h3", 4);
        i0.ɵɵtext(5, "Admit to unit");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 5);
        i0.ɵɵelementStart(7, "button", 6);
        i0.ɵɵlistener("click", function AddToUnitModalComponent_Template_button_click_7_listener() { return ctx.cancel(); });
        i0.ɵɵelement(8, "mat-icon", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "i", 9);
        i0.ɵɵtext(11, "*");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(12, " Resident ");
        i0.ɵɵelementStart(13, "div");
        i0.ɵɵelement(14, "dnaR-search", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "div", 11);
        i0.ɵɵelementStart(16, "div", 12);
        i0.ɵɵelementStart(17, "button", 13);
        i0.ɵɵlistener("click", function AddToUnitModalComponent_Template_button_click_17_listener() { return ctx.cancel(); });
        i0.ɵɵtext(18, "Cancel");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "button", 14);
        i0.ɵɵlistener("click", function AddToUnitModalComponent_Template_button_click_19_listener() { return ctx.submit(); });
        i0.ɵɵtext(20, "Admit");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(14);
        i0.ɵɵproperty("formSubmittedObservable", ctx.formSubmitted.asObservable());
    } }, directives: [i1.MatDialogTitle, i2.MatButton, i3.MatTooltip, i4.MatIcon, i5.DnaRSearch], styles: [".required[_ngcontent-%COMP%] {\n  color: #C53535;\n  padding-right: 2px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AddToUnitModalComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'add-to-unit-modal',
                templateUrl: './add-to-unit-modal.component.html',
                styleUrls: ['./add-to-unit-modal.component.less']
            }]
    }], function () { return [{ type: i1.MatDialogRef }]; }, { hideDialogEvent: [{
            type: core_1.Input
        }] }); })();
