"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@angular/forms");
const isNum_1 = require("./isNum");
/**
 * Validator that requires the control have a number value with a scale equal to or less than 4.
 *
 * @returns An error map with `scaleTooHigh` property if the scale validation check failes, otherwise `null`.
 */
function fourDecimalsNumberValidator(control) {
    const value = String(control.value || "").replace(",", "");
    if (!(value && isNum_1.isNum(value))) {
        return null;
    }
    if (!/^\d+(.\d{0,4})?$/gm.test(value)) {
        return { scaleTooHigh: true };
    }
    return null;
}
exports.fourDecimalsNumberValidator = fourDecimalsNumberValidator;
