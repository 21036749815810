"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const i0 = require("@angular/core");
/**
 * Returns the elements in the items array that match the specified optional service stopped value.
 *
 * @example
 * *ngFor="let item of residentcyOptionalServices | optionalServiceStopped: true"
 */
class ResidencyOptionalServiceStoppedFilter {
    /**
     * Returns the elements in the items array that match the specified optional service stopped value.
     *
     * @param items The array of items.
     * @param stopped The stopped value.
     * @returns The elements in the items array that match the specified optional service stopped value.
     */
    transform(items, stopped) {
        if (items) {
            if (items instanceof forms_1.FormArray) {
                return items.controls.filter((e) => { var _a, _b; return ((_b = (_a = e.value) === null || _a === void 0 ? void 0 : _a.stopped) !== null && _b !== void 0 ? _b : false) === stopped; });
            }
            if (items.filter) {
                return items.filter((e) => { var _a; return ((_a = e.stopped) !== null && _a !== void 0 ? _a : false) === stopped; });
            }
            return null;
        }
        return items;
    }
}
exports.ResidencyOptionalServiceStoppedFilter = ResidencyOptionalServiceStoppedFilter;
ResidencyOptionalServiceStoppedFilter.ɵfac = function ResidencyOptionalServiceStoppedFilter_Factory(t) { return new (t || ResidencyOptionalServiceStoppedFilter)(); };
ResidencyOptionalServiceStoppedFilter.ɵpipe = i0.ɵɵdefinePipe({ name: "residencyOptionalServiceStopped", type: ResidencyOptionalServiceStoppedFilter, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidencyOptionalServiceStoppedFilter, [{
        type: core_1.Pipe,
        args: [{ name: "residencyOptionalServiceStopped" }]
    }], null, null); })();
