"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../enums/ilu-dmf-rate-type.enum");
require("../enums/ilu-dmf-rate-period.enum");
class IndependentLivingAllocationDmfPeriodRateTypeDto {
}
exports.IndependentLivingAllocationDmfPeriodRateTypeDto = IndependentLivingAllocationDmfPeriodRateTypeDto;
class IndependentLivingAllocationDmfPeriodRateTypePeriodTypeDto {
}
exports.IndependentLivingAllocationDmfPeriodRateTypePeriodTypeDto = IndependentLivingAllocationDmfPeriodRateTypePeriodTypeDto;
