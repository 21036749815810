"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const i0 = require("@angular/core");
class EmailValidatorDirective {
    constructor() {
        this.emailControl = null;
        this.email = "";
        this.required = false;
        this.id = "";
        this.name = "";
        this.emailsToCompare = "";
        this.customEvent = new core_1.EventEmitter();
    }
    validate(control) {
        this.emailControl = control;
        this.email = this.emailControl.value;
        if (this.email && this.email.length > this.validLength) {
            this.emailControl.markAsTouched();
            this.makeFormInvalid();
            return { invalidLength: true };
        }
        if (!this.email && this.required) {
            this.makeFormInvalid();
            return { emailrequired: true };
        }
        if (this.emailsToCompare && this.totalValidLength && this.emailsToCompare.length > this.totalValidLength) {
            this.emailControl.markAsTouched();
            this.makeFormInvalid();
            return { invalidCCEmailLength: true };
        }
        this.makeFormValid();
        if (this.saveForm) {
            this.saveForm.$setPristine();
        }
        return null;
    }
    onBlur() {
        if (this.emailControl) {
            if (!this.email && this.required) {
                this.emailControl.setErrors({ emailrequired: true });
                this.makeFormInvalid();
                return;
            }
            else if (!this.email && !this.required) {
                this.emailControl.setErrors(null);
                this.makeFormValid();
                return;
            }
            // Email must not contain multiple @ symbol
            if (this.email.split("@").length > 2) {
                this.emailControl.setErrors({ multipleAt: true });
                this.makeFormInvalid();
                return;
            }
            // Email must contain @ symbol
            if (!this.email.includes("@")) {
                this.emailControl.setErrors({ missingAt: true });
                this.makeFormInvalid();
                return;
            }
            const domain = this.email.split(".");
            if (domain.length > 1) {
                // Email must contain domain name
                const firstPart = domain[0];
                if (firstPart[firstPart.length - 1] === "@") {
                    this.emailControl.setErrors({ missingDomain: true });
                    this.makeFormInvalid();
                    return;
                }
            }
            else {
                // Email must contain domain extension e.g. .com
                this.emailControl.setErrors({ missingDomainExtension: true });
                this.makeFormInvalid();
                return;
            }
            // Email must not contain spaces
            if (this.email.includes(" ")) {
                this.emailControl.setErrors({ invalidSpace: true });
                this.makeFormInvalid();
                return;
            }
            const invalidCharactersPattern = /[^a-zA-Z0-9._%+-@]/;
            // Email must not include special characters
            if (invalidCharactersPattern.test(this.email)) {
                this.emailControl.setErrors({ invalidCharacters: true });
                this.makeFormInvalid();
                return;
            }
            const emailPattern = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]+$/;
            // Email address must follow proper format
            if (!emailPattern.test(this.email)) {
                this.emailControl.setErrors({ invalidEmail: true });
                this.makeFormInvalid();
                return;
            }
            // Email address must be unique
            if (this.id && this.unique) {
                // This will match the current ccemail input with all ccemail inputs including itself.
                // Ex. Current input in ccemail is "test@cc.com" and it will compare with other 3 ccemails including itself like "test@cc.com, test@cc.com, test2@cc.com", so if and only if duplicate count is > 1 then only it will fire unique validation.
                let count = 0;
                let emails = this.emailsToCompare.split(",");
                for (let index = 0; index < emails.length; index++) {
                    const element = emails[index];
                    if (element === this.email) {
                        count++;
                    }
                    if (count > 1) {
                        break;
                    }
                }
                if (count > 1) {
                    this.emailControl.setErrors({ duplicateEmail: true });
                    this.makeFormInvalid();
                    return;
                }
                else {
                    this.checkDuplicateEmailforAll();
                }
            }
            //Email address length validation
            if (this.emailsToCompare && this.totalValidLength && this.emailsToCompare.length > this.totalValidLength) {
                this.emailControl.setErrors({ invalidCCEmailLength: true });
                this.makeFormInvalid();
                return;
            }
            if (!this.emailsToCompare && this.email && this.email.length > this.validLength) {
                this.makeFormInvalid();
                this.emailControl.setErrors({ invalidLength: true });
                return;
            }
            this.emailControl.setErrors(null);
            this.makeFormValid();
            if (this.saveForm) {
                this.saveForm.$setPristine();
            }
        }
    }
    makeFormInvalid() {
        if (this.saveForm && this.saveForm[this.name]) {
            this.saveForm[this.name].$setValidity("customError", false);
        }
    }
    makeFormValid() {
        if (this.saveForm && this.saveForm[this.name]) {
            this.saveForm[this.name].$setValidity("customError", true);
        }
    }
    checkDuplicateEmailforAll() {
        let count = 0;
        let isDuplicate = false;
        let emails = this.emailsToCompare.split(",");
        for (let i = 0; i < emails.length; i++) {
            let mail = emails[i];
            count = 0;
            for (let j = 0; j < emails.length; j++) {
                const element = emails[j];
                if (element === mail) {
                    count++;
                }
                if (count > 1) {
                    break;
                }
            }
            if (count > 1) {
                isDuplicate = true;
                this.emailControl.setErrors({ duplicateEmail: true });
                this.makeFormInvalid();
            }
        }
        if (!isDuplicate) {
            this.customEvent.emit({ isUnique: true });
        }
    }
}
exports.EmailValidatorDirective = EmailValidatorDirective;
EmailValidatorDirective.ɵfac = function EmailValidatorDirective_Factory(t) { return new (t || EmailValidatorDirective)(); };
EmailValidatorDirective.ɵdir = i0.ɵɵdefineDirective({ type: EmailValidatorDirective, selectors: [["", "emailValidator", ""]], hostBindings: function EmailValidatorDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("blur", function EmailValidatorDirective_blur_HostBindingHandler() { return ctx.onBlur(); });
    } }, inputs: { required: "required", id: "id", unique: "unique", name: "name", validLength: "validLength", totalValidLength: "totalValidLength", saveForm: "saveForm", emailsToCompare: "emailsToCompare" }, outputs: { customEvent: "customEvent" }, features: [i0.ɵɵProvidersFeature([
            {
                provide: forms_1.NG_VALIDATORS,
                useExisting: core_1.forwardRef(() => EmailValidatorDirective),
                multi: true,
            },
        ])] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EmailValidatorDirective, [{
        type: core_1.Directive,
        args: [{
                selector: "[emailValidator]",
                providers: [
                    {
                        provide: forms_1.NG_VALIDATORS,
                        useExisting: core_1.forwardRef(() => EmailValidatorDirective),
                        multi: true,
                    },
                ],
            }]
    }], function () { return []; }, { required: [{
            type: core_1.Input
        }], id: [{
            type: core_1.Input
        }], unique: [{
            type: core_1.Input
        }], name: [{
            type: core_1.Input
        }], validLength: [{
            type: core_1.Input
        }], totalValidLength: [{
            type: core_1.Input
        }], saveForm: [{
            type: core_1.Input
        }], emailsToCompare: [{
            type: core_1.Input
        }], customEvent: [{
            type: core_1.Output
        }], onBlur: [{
            type: core_1.HostListener,
            args: ["blur"]
        }] }); })();
