"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("./dnaR-dropdown-multiSelect-list-item");
const i0 = require("@angular/core");
class DnaRDropdownMultiSelectFilter {
    transform(items, filter) {
        if (!items || !filter) {
            return items;
        }
        return items.filter((item) => this.applyFilter(item, filter));
    }
    applyFilter(item, filter) {
        return !(filter.text && item.text && item.text.toLowerCase().indexOf(filter.text.toLowerCase()) === -1);
    }
}
exports.DnaRDropdownMultiSelectFilter = DnaRDropdownMultiSelectFilter;
DnaRDropdownMultiSelectFilter.ɵfac = function DnaRDropdownMultiSelectFilter_Factory(t) { return new (t || DnaRDropdownMultiSelectFilter)(); };
DnaRDropdownMultiSelectFilter.ɵpipe = i0.ɵɵdefinePipe({ name: "dnaRDropdownMultiSelectFilter", type: DnaRDropdownMultiSelectFilter, pure: false });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRDropdownMultiSelectFilter, [{
        type: core_1.Pipe,
        args: [{
                name: 'dnaRDropdownMultiSelectFilter',
                pure: false
            }]
    }], null, null); })();
