"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Compares the ids of the two provided objects.
 *
 * @param o1 Option value.
 * @param o2 Selected value.
 * @returns true when o1 is the same as o2 otherwise false.
 */
function compareIds(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : false;
}
exports.compareIds = compareIds;
