"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const core_1 = require("@angular/core");
const static_1 = require("@angular/upgrade/static");
const shared_module_1 = require("../common/shared.module");
const platform_browser_1 = require("@angular/platform-browser");
const forms_1 = require("@angular/forms");
const ilu_leads_dashboard_component_1 = require("./dashboard/ilu-leads-dashboard.component");
const ilu_leads_dashboard_column_component_1 = require("./dashboard/ilu-leads-dashboard-column.component");
const ilu_lead_card_component_1 = require("./dashboard/ilu-lead-card.component");
const independent_living_service_1 = require("./services/independent-living.service");
const mobility_requirements_component_1 = require("./mobility-requirements/mobility-requirements.component");
const radio_1 = require("@angular/material/radio");
const dnaR_snack_bar_module_1 = require("../common/components/dnaR-snack-bar/dnaR-snack-bar.module");
const accommodation_preferences_component_1 = require("./accommodation-preferences/components/accommodation-preferences.component");
const ILU_status_component_1 = require("./ilu-status/components/ILU-status.component");
const independent_living_states_1 = require("./independent-living.states");
const drag_drop_1 = require("@angular/cdk/drag-drop");
const dnaR_dropdown_multiSelect_module_1 = require("../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const dnaR_action_modal_component_1 = require("../common/components/dnaR-action-modal/dnaR-action-modal.component");
const accommodation_preferences_service_1 = require("./services/accommodation-preferences.service");
const ilu_lead_card_dropdown_component_1 = require("./dashboard/ilu-lead-card-dropdown.component");
const menu_1 = require("@angular/material/menu");
const button_1 = require("@angular/material/button");
const dialog_1 = require("@angular/material/dialog");
const tooltip_1 = require("@angular/material/tooltip");
const icon_1 = require("@angular/material/icon");
const button_toggle_1 = require("@angular/material/button-toggle");
const archived_leads_component_1 = require("./archived-leads/components/archived-leads.component");
const lead_card_component_1 = require("./archived-leads/components/lead-card.component");
const loading_lead_card_component_1 = require("./archived-leads/components/loading-lead-card.component");
const enquiry_lead_card_component_1 = require("./dashboard/enquiry-lead-card.component");
const prospectName_pipe_1 = require("./pipes/prospectName.pipe");
const residentName_pipe_1 = require("./pipes/residentName.pipe");
const residencyResidentName_pipe_1 = require("./pipes/residencyResidentName.pipe");
const dnaR_input_module_1 = require("../common/components/dnaR-input/dnaR-input.module");
const period_component_1 = require("./components/period/period.component");
const add_new_prospect_component_1 = require("./components/add-new-prospect/add-new-prospect.component");
const ilu_finance_details_edit_tab_component_1 = require("./finance-details-tab/components/ilu-finance-details-edit-tab.component");
const ilu_finance_details_view_tab_component_1 = require("./finance-details-tab/components/ilu-finance-details-view-tab.component");
const dnar_alert_module_1 = require("../common/components/dnaR-alert/dnar-alert.module");
const dnaR_datePicker_module_1 = require("../common/components/dnaR-datePicker/dnaR-datePicker.module");
const assign_accommodation_component_1 = require("./finance-details-tab/components/assign-accommodation.component");
const iluAgreementTypeText_pipe_1 = require("./pipes/iluAgreementTypeText.pipe");
const iluDmfCalculatedOnText_pipe_1 = require("./pipes/iluDmfCalculatedOnText.pipe");
const iluDmfCommencesOnText_pipe_1 = require("./pipes/iluDmfCommencesOnText.pipe");
const iluDmfPostTimingText_pipe_1 = require("./pipes/iluDmfPostTimingText.pipe");
const iluDmfRatePeriodText_pipe_1 = require("./pipes/iluDmfRatePeriodText.pipe");
const iluFinanceResidentBillPayerName_pipe_1 = require("./pipes/iluFinanceResidentBillPayerName.pipe");
const rateValue_pipe_1 = require("./pipes/rateValue.pipe");
const optionalServiceCurrentRate_pipe_1 = require("./pipes/optionalServiceCurrentRate.pipe");
const valuesWithEffectiveDateValue_pipe_1 = require("./pipes/valuesWithEffectiveDateValue.pipe");
const selected_facility_service_1 = require("./services/selected-facility.service");
const sum_pipe_1 = require("./pipes/sum.pipe");
const accumulatePeriodRateFilter_pipe_1 = require("./pipes/accumulatePeriodRateFilter.pipe");
const rate_total_component_1 = require("./finance-details-tab/components/rate-total.component");
const settlement_cost_total_pipe_1 = require("./finance-details-tab/pipes/settlement-cost-total.pipe");
const finalise_settlement_dialog_component_1 = require("./finalise-settlement-dialog/finalise-settlement-dialog.component");
const dnaR_email_input_module_1 = require("../common/components/dnaR-email-input/dnaR-email-input.module");
const optionalServiceBillingDescription_pipe_1 = require("./pipes/optionalServiceBillingDescription.pipe");
const residencyOptionalServiceStoppedFilter_pipe_1 = require("./finance-details-tab/pipes/residencyOptionalServiceStoppedFilter.pipe");
const value_with_effective_date_module_1 = require("../common/components/value-with-effective-date/value-with-effective-date.module");
const add_to_unit_modal_component_1 = require("./admit-to-unit/add-to-unit-modal.component");
const dnaR_search_module_1 = require("../common/components/dnaR-search/dnaR-search.module");
const dnaR_timePicker_module_1 = require("../common/components/dnaR-timePicker/dnaR-timePicker.module");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
// Downgrade and register items in AngularJs
angular
    .module("app")
    .directive("mobilityRequirements", static_1.downgradeComponent({ component: mobility_requirements_component_1.MobilityRequirementsComponent }))
    .directive("accommodationPreferences", static_1.downgradeComponent({ component: accommodation_preferences_component_1.AccommodationPreferencesComponent }))
    .directive("iluStatus", static_1.downgradeComponent({ component: ILU_status_component_1.ILUStatusComponent }))
    .directive("addNewProspect", static_1.downgradeComponent({ component: add_new_prospect_component_1.AddNewProspectComponent }))
    .directive("iluFinanceDetailsView", static_1.downgradeComponent({ component: ilu_finance_details_view_tab_component_1.FinanceDetailsViewTabComponent }))
    .directive("iluFinanceDetailsEdit", static_1.downgradeComponent({ component: ilu_finance_details_edit_tab_component_1.FinanceDetailsEditTabComponent }))
    .directive("finaliseSettlementDialog", static_1.downgradeComponent({ component: finalise_settlement_dialog_component_1.FinaliseSettlementDialogComponent }))
    .directive('addToUnitModal', static_1.downgradeComponent({ component: add_to_unit_modal_component_1.AddToUnitModalComponent }));
class IndependentLivingModule {
}
exports.IndependentLivingModule = IndependentLivingModule;
IndependentLivingModule.ɵmod = i0.ɵɵdefineNgModule({ type: IndependentLivingModule });
IndependentLivingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function IndependentLivingModule_Factory(t) { return new (t || IndependentLivingModule)(); }, providers: [
        independent_living_service_1.IndependentLivingService,
        accommodation_preferences_service_1.AccommodationPreferencesService,
        selected_facility_service_1.SelectedFacilityService
    ], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            radio_1.MatRadioModule,
            dnaR_snack_bar_module_1.DnaRSnackBarModule,
            button_1.MatButtonModule,
            icon_1.MatIconModule,
            tooltip_1.MatTooltipModule,
            dialog_1.MatDialogModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
            dnaR_search_module_1.DnaRSearchModule,
            dnaR_email_input_module_1.DnaREmailInputModule,
            drag_drop_1.DragDropModule,
            menu_1.MatMenuModule,
            button_toggle_1.MatButtonToggleModule,
            dnar_alert_module_1.DnaRAlertModule,
            dnaR_datePicker_module_1.DnaRDatePickerModule,
            dnaR_input_module_1.DnaRInputModule,
            dnaR_timePicker_module_1.DnaRTimePickerModule,
            value_with_effective_date_module_1.ValueWithEffectiveDateDialogModule,
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: independent_living_states_1.states }),
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(IndependentLivingModule, { declarations: [mobility_requirements_component_1.MobilityRequirementsComponent,
        accommodation_preferences_component_1.AccommodationPreferencesComponent,
        ILU_status_component_1.ILUStatusComponent,
        ilu_leads_dashboard_component_1.ILULeadsDashboardComponent,
        ilu_leads_dashboard_column_component_1.ILULeadsDashboardColumnComponent,
        ilu_lead_card_component_1.ILULeadCardComponent,
        ilu_lead_card_dropdown_component_1.IluLeadCardDropdownComponent,
        dnaR_action_modal_component_1.DnaRActionModalComponent,
        archived_leads_component_1.ArchivedLeadsComponent,
        lead_card_component_1.LeadCardComponent,
        loading_lead_card_component_1.LoadingLeadCardComponent,
        enquiry_lead_card_component_1.EnquiryLeadCardComponent,
        prospectName_pipe_1.ProspectNamePipe,
        residentName_pipe_1.ResidentNamePipe,
        residencyResidentName_pipe_1.ResidencyResidentNamePipe,
        period_component_1.PeriodComponent,
        add_new_prospect_component_1.AddNewProspectComponent,
        ilu_finance_details_edit_tab_component_1.FinanceDetailsEditTabComponent,
        ilu_finance_details_view_tab_component_1.FinanceDetailsViewTabComponent,
        assign_accommodation_component_1.AssignAccommodationComponent,
        iluAgreementTypeText_pipe_1.IluAgreementTypeTextPipe,
        iluDmfCalculatedOnText_pipe_1.IluDmfCalculatedOnTextPipe,
        iluDmfCommencesOnText_pipe_1.IluDmfCommencesOnTextPipe,
        iluDmfPostTimingText_pipe_1.IluDmfPostTimingTextPipe,
        iluDmfRatePeriodText_pipe_1.IluDmfRatePeriodTextPipe,
        iluFinanceResidentBillPayerName_pipe_1.IluFinanceResidentBillPayerNamePipe,
        rateValue_pipe_1.RateValuePipe,
        optionalServiceCurrentRate_pipe_1.OptionalServiceCurrentRatePipe,
        valuesWithEffectiveDateValue_pipe_1.ValuesWithEffectiveDateValuePipe,
        sum_pipe_1.SumPipe,
        accumulatePeriodRateFilter_pipe_1.AccumulatePeriodRateFilterPipe,
        rate_total_component_1.RateTotalComponent,
        settlement_cost_total_pipe_1.SettlementCostTotalPipe,
        finalise_settlement_dialog_component_1.FinaliseSettlementDialogComponent,
        optionalServiceBillingDescription_pipe_1.OptionalServiceBillingDescriptionPipe,
        residencyOptionalServiceStoppedFilter_pipe_1.ResidencyOptionalServiceStoppedFilter,
        add_to_unit_modal_component_1.AddToUnitModalComponent], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        radio_1.MatRadioModule,
        dnaR_snack_bar_module_1.DnaRSnackBarModule,
        button_1.MatButtonModule,
        icon_1.MatIconModule,
        tooltip_1.MatTooltipModule,
        dialog_1.MatDialogModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
        dnaR_search_module_1.DnaRSearchModule,
        dnaR_email_input_module_1.DnaREmailInputModule,
        drag_drop_1.DragDropModule,
        menu_1.MatMenuModule,
        button_toggle_1.MatButtonToggleModule,
        dnar_alert_module_1.DnaRAlertModule,
        dnaR_datePicker_module_1.DnaRDatePickerModule,
        dnaR_input_module_1.DnaRInputModule,
        dnaR_timePicker_module_1.DnaRTimePickerModule,
        value_with_effective_date_module_1.ValueWithEffectiveDateDialogModule,
        forms_1.FormsModule,
        forms_1.ReactiveFormsModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IndependentLivingModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    radio_1.MatRadioModule,
                    dnaR_snack_bar_module_1.DnaRSnackBarModule,
                    button_1.MatButtonModule,
                    icon_1.MatIconModule,
                    tooltip_1.MatTooltipModule,
                    dialog_1.MatDialogModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
                    dnaR_search_module_1.DnaRSearchModule,
                    dnaR_email_input_module_1.DnaREmailInputModule,
                    drag_drop_1.DragDropModule,
                    menu_1.MatMenuModule,
                    button_toggle_1.MatButtonToggleModule,
                    dnar_alert_module_1.DnaRAlertModule,
                    dnaR_datePicker_module_1.DnaRDatePickerModule,
                    dnaR_input_module_1.DnaRInputModule,
                    dnaR_timePicker_module_1.DnaRTimePickerModule,
                    value_with_effective_date_module_1.ValueWithEffectiveDateDialogModule,
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: independent_living_states_1.states }),
                ],
                providers: [
                    independent_living_service_1.IndependentLivingService,
                    accommodation_preferences_service_1.AccommodationPreferencesService,
                    selected_facility_service_1.SelectedFacilityService
                ],
                declarations: [
                    mobility_requirements_component_1.MobilityRequirementsComponent,
                    accommodation_preferences_component_1.AccommodationPreferencesComponent,
                    ILU_status_component_1.ILUStatusComponent,
                    ilu_leads_dashboard_component_1.ILULeadsDashboardComponent,
                    ilu_leads_dashboard_column_component_1.ILULeadsDashboardColumnComponent,
                    ilu_lead_card_component_1.ILULeadCardComponent,
                    ilu_lead_card_dropdown_component_1.IluLeadCardDropdownComponent,
                    dnaR_action_modal_component_1.DnaRActionModalComponent,
                    archived_leads_component_1.ArchivedLeadsComponent,
                    lead_card_component_1.LeadCardComponent,
                    loading_lead_card_component_1.LoadingLeadCardComponent,
                    enquiry_lead_card_component_1.EnquiryLeadCardComponent,
                    prospectName_pipe_1.ProspectNamePipe,
                    residentName_pipe_1.ResidentNamePipe,
                    residencyResidentName_pipe_1.ResidencyResidentNamePipe,
                    period_component_1.PeriodComponent,
                    add_new_prospect_component_1.AddNewProspectComponent,
                    ilu_finance_details_edit_tab_component_1.FinanceDetailsEditTabComponent,
                    ilu_finance_details_view_tab_component_1.FinanceDetailsViewTabComponent,
                    assign_accommodation_component_1.AssignAccommodationComponent,
                    iluAgreementTypeText_pipe_1.IluAgreementTypeTextPipe,
                    iluDmfCalculatedOnText_pipe_1.IluDmfCalculatedOnTextPipe,
                    iluDmfCommencesOnText_pipe_1.IluDmfCommencesOnTextPipe,
                    iluDmfPostTimingText_pipe_1.IluDmfPostTimingTextPipe,
                    iluDmfRatePeriodText_pipe_1.IluDmfRatePeriodTextPipe,
                    iluFinanceResidentBillPayerName_pipe_1.IluFinanceResidentBillPayerNamePipe,
                    rateValue_pipe_1.RateValuePipe,
                    optionalServiceCurrentRate_pipe_1.OptionalServiceCurrentRatePipe,
                    valuesWithEffectiveDateValue_pipe_1.ValuesWithEffectiveDateValuePipe,
                    sum_pipe_1.SumPipe,
                    accumulatePeriodRateFilter_pipe_1.AccumulatePeriodRateFilterPipe,
                    rate_total_component_1.RateTotalComponent,
                    settlement_cost_total_pipe_1.SettlementCostTotalPipe,
                    finalise_settlement_dialog_component_1.FinaliseSettlementDialogComponent,
                    optionalServiceBillingDescription_pipe_1.OptionalServiceBillingDescriptionPipe,
                    residencyOptionalServiceStoppedFilter_pipe_1.ResidencyOptionalServiceStoppedFilter,
                    add_to_unit_modal_component_1.AddToUnitModalComponent,
                ]
            }]
    }], null, null); })();
