"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class NullToZeroPipe {
    transform(num) {
        return num !== null && num !== void 0 ? num : 0;
    }
}
exports.NullToZeroPipe = NullToZeroPipe;
NullToZeroPipe.ɵfac = function NullToZeroPipe_Factory(t) { return new (t || NullToZeroPipe)(); };
NullToZeroPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "nullToZero", type: NullToZeroPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NullToZeroPipe, [{
        type: core_1.Pipe,
        args: [{ name: 'nullToZero' }]
    }], null, null); })();
