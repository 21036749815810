"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const snack_bar_1 = require("@angular/material/snack-bar");
const dnaR_snack_bar_service_1 = require("./dnaR-snack-bar.service");
const dnaR_snack_bar_component_1 = require("./dnaR-snack-bar.component");
const i0 = require("@angular/core");
class DnaRSnackBarModule {
    static forRoot() {
        return {
            ngModule: DnaRSnackBarModule,
        };
    }
}
exports.DnaRSnackBarModule = DnaRSnackBarModule;
DnaRSnackBarModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRSnackBarModule });
DnaRSnackBarModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRSnackBarModule_Factory(t) { return new (t || DnaRSnackBarModule)(); }, providers: [
        dnaR_snack_bar_service_1.DnaRSnackBarService,
        {
            provide: snack_bar_1.MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 8000,
                horizontalPosition: "center",
                verticalPosition: "bottom",
                panelClass: "dnar-snack-bar",
            },
        },
    ], imports: [[common_1.CommonModule, snack_bar_1.MatSnackBarModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRSnackBarModule, { declarations: [dnaR_snack_bar_component_1.DnaRSnackBarComponent], imports: [common_1.CommonModule, snack_bar_1.MatSnackBarModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSnackBarModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [common_1.CommonModule, snack_bar_1.MatSnackBarModule],
                providers: [
                    dnaR_snack_bar_service_1.DnaRSnackBarService,
                    {
                        provide: snack_bar_1.MAT_SNACK_BAR_DEFAULT_OPTIONS,
                        useValue: {
                            duration: 8000,
                            horizontalPosition: "center",
                            verticalPosition: "bottom",
                            panelClass: "dnar-snack-bar",
                        },
                    },
                ],
                declarations: [dnaR_snack_bar_component_1.DnaRSnackBarComponent],
            }]
    }], null, null); })();
