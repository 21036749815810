"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const dnaR_email_input_component_1 = require("./dnaR-email-input.component");
const email_validator_directive_1 = require("./email-validator.directive");
const i0 = require("@angular/core");
class DnaREmailInputModule {
    static forRoot() {
        return {
            ngModule: DnaREmailInputModule
        };
    }
}
exports.DnaREmailInputModule = DnaREmailInputModule;
DnaREmailInputModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaREmailInputModule });
DnaREmailInputModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaREmailInputModule_Factory(t) { return new (t || DnaREmailInputModule)(); }, imports: [[common_1.CommonModule, forms_1.FormsModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaREmailInputModule, { declarations: [dnaR_email_input_component_1.DnaREmailInput, email_validator_directive_1.EmailValidatorDirective], imports: [common_1.CommonModule, forms_1.FormsModule], exports: [dnaR_email_input_component_1.DnaREmailInput] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaREmailInputModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [common_1.CommonModule, forms_1.FormsModule],
                declarations: [dnaR_email_input_component_1.DnaREmailInput, email_validator_directive_1.EmailValidatorDirective],
                exports: [dnaR_email_input_component_1.DnaREmailInput],
                entryComponents: [
                    dnaR_email_input_component_1.DnaREmailInput
                ],
            }]
    }], null, null); })();
