"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Checks if the provided value is a number.
 *
 * @param value The value to check.
 * @returns Returns `true` when provided value is a number, otherwise `false`.
 *
 * @description From https://stackoverflow.com/a/78126159/651104
 */
exports.isNum = (value) => typeof value !== "object" && !Number.isNaN(+String((String(value) || "").replace(/[^0-9\.\-e]/, "") !== String(value) || value === "" ? NaN : value));
