"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("angular");
const core_1 = require("@angular/core");
/**
 * An injectable token for the AngularJS $RootScope.
 */
exports.ROOT_SCOPE = new core_1.InjectionToken("Angular JS Root Scope");
/**
 * An provider for @see ROOT_SCOPE.
 */
exports.rootScopeProvider = {
    provide: exports.ROOT_SCOPE,
    useFactory: ($inject) => {
        return $inject.get("$rootScope");
    },
    deps: ["$injector"],
};
