"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../dtos/resident-search-dto");
const i0 = require("@angular/core");
class ResidentSearchService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getResidents = (searchString) => {
            return this.dataService.get("residents/search-single-prospect-ilu-enquiries?searchStr=" + searchString, true);
        };
    }
}
exports.ResidentSearchService = ResidentSearchService;
ResidentSearchService.ɵfac = function ResidentSearchService_Factory(t) { return new (t || ResidentSearchService)(i0.ɵɵinject('dataService')); };
ResidentSearchService.ɵprov = i0.ɵɵdefineInjectable({ token: ResidentSearchService, factory: ResidentSearchService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidentSearchService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
