"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ListItem {
    constructor(source) {
        var _a, _b;
        this.isHeader = false;
        this.isDisabled = false;
        if (typeof source === 'string') {
            this.id = this.text = source;
        }
        if (typeof source === 'object') {
            this.id = source.id;
            this.text = source.text;
            this.isHeader = (_a = source.isHeader) !== null && _a !== void 0 ? _a : false;
            this.isDisabled = (_b = source.isDisabled) !== null && _b !== void 0 ? _b : false;
        }
    }
}
exports.ListItem = ListItem;
