"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const dnaR_timePicker_component_1 = require("./dnaR-timePicker.component");
const dnaR_timePicker_click_outside_directive_1 = require("./dnaR-timePicker-click-outside.directive");
const dnaR_spinner_module_1 = require("../dnaR-spinner/dnaR-spinner.module");
const i0 = require("@angular/core");
class DnaRTimePickerModule {
    static forRoot() {
        return {
            ngModule: DnaRTimePickerModule
        };
    }
}
exports.DnaRTimePickerModule = DnaRTimePickerModule;
DnaRTimePickerModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRTimePickerModule });
DnaRTimePickerModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRTimePickerModule_Factory(t) { return new (t || DnaRTimePickerModule)(); }, imports: [[common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRTimePickerModule, { declarations: [dnaR_timePicker_component_1.DnaRTimePicker, dnaR_timePicker_click_outside_directive_1.ClickOutsideDirective], imports: [common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule], exports: [dnaR_timePicker_component_1.DnaRTimePicker] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRTimePickerModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule],
                declarations: [dnaR_timePicker_component_1.DnaRTimePicker, dnaR_timePicker_click_outside_directive_1.ClickOutsideDirective],
                exports: [dnaR_timePicker_component_1.DnaRTimePicker],
            }]
    }], null, null); })();
