"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ilu_dmf_rate_period_enum_1 = require("../../enums/ilu-dmf-rate-period.enum");
const ilu_dmf_rate_type_enum_1 = require("../../enums/ilu-dmf-rate-type.enum");
const isNum_1 = require("../../../common/validators/isNum");
require("./rate-total");
/**
 * Calculates running total of a DMF rate schedule line.
 *
 * Calculates the total for all rates upto and including the rate line provided
 *
 * @param uptoPeriodRate The rate line to calculate the running total upto.
 * @param periodRates The full DMF rate schedule.
 * @returns A @see RateTotal containing the running total.
 */
function periodRateLineRunningTotal(uptoPeriodRate, periodRates) {
    if (uptoPeriodRate && periodRates) {
        return periodRateTotals(periodRates.slice(0, periodRates.indexOf(uptoPeriodRate) + 1));
    }
    return {
        dollar: null,
        percentage: null,
    };
}
exports.periodRateLineRunningTotal = periodRateLineRunningTotal;
/**
 * Calculates total of a DMF rate schedule.
 *
 * @param periodRates The DMF rate schedule
 * @returns A @see RateTotal containing the total.
 */
function periodRateTotals(periodRates) {
    let dollarTotal = null;
    let percentageTotal = null;
    periodRates.forEach((periodRate) => {
        const lineTotals = periodRateLineTotal(periodRate);
        if (lineTotals.dollar !== null) {
            dollarTotal = (dollarTotal !== null && dollarTotal !== void 0 ? dollarTotal : 0) + lineTotals.dollar;
        }
        if (lineTotals.percentage !== null) {
            percentageTotal = (percentageTotal !== null && percentageTotal !== void 0 ? percentageTotal : 0) + lineTotals.percentage;
        }
    });
    return {
        dollar: dollarTotal,
        percentage: percentageTotal,
    };
}
exports.periodRateTotals = periodRateTotals;
/**
 * Calculates total for a given DMF rate schedule line.
 *
 * @param periodRate The rate line.
 * @returns A @see RateTotal containing the total.
 */
function periodRateLineTotal(periodRate) {
    var _a, _b, _c;
    let dollarTotal = null;
    let percentageTotal = null;
    const rateValue = periodRate.rateValue;
    const rateType = (_a = periodRate.independentLivingAllocationDmfPeriodRateType) === null || _a === void 0 ? void 0 : _a.rateType;
    const accumulateRate = ((_b = periodRate.independentLivingAllocationDmfPeriodRateType) === null || _b === void 0 ? void 0 : _b.accumulatePeriodRates) && rateType === ilu_dmf_rate_type_enum_1.IluDmfRateType.Percentage;
    // Minimum information needed to proceed.
    if (rateType && isNum_1.isNum(rateValue)) {
        let rateLineTotal = null;
        // There are difference approaches to determine how a rate line contributes to the total depending if it is flat vs accumulate.
        if (accumulateRate) {
            // Accumulate line total = number of periods x periods per year x rate
            const periodType = (_c = periodRate.independentLivingAllocationDmfPeriodRateTypePeriodType) === null || _c === void 0 ? void 0 : _c.periodType;
            const ratePeriodValue = periodRate.ratePeriodValue;
            if (periodType && isNum_1.isNum(ratePeriodValue)) {
                if (periodType === ilu_dmf_rate_period_enum_1.IluDmfRatePeriod.Week) {
                    // Week
                    rateLineTotal = Number(rateValue) * 7 / 365;
                }
                if (periodType === ilu_dmf_rate_period_enum_1.IluDmfRatePeriod.Month) {
                    // Month
                    rateLineTotal = Number(rateValue) / 12;
                }
                if (periodType === ilu_dmf_rate_period_enum_1.IluDmfRatePeriod.Year) {
                    // Year
                    rateLineTotal = Number(rateValue);
                }
                rateLineTotal = Number(ratePeriodValue) * (rateLineTotal !== null && rateLineTotal !== void 0 ? rateLineTotal : 0);
            }
        }
        else {
            // Flat is ... flat.
            rateLineTotal = Number(rateValue);
        }
        if (rateLineTotal) {
            if (rateType === ilu_dmf_rate_type_enum_1.IluDmfRateType.Dollar) {
                dollarTotal = (dollarTotal !== null && dollarTotal !== void 0 ? dollarTotal : 0) + rateLineTotal;
            }
            if (rateType === ilu_dmf_rate_type_enum_1.IluDmfRateType.Percentage) {
                percentageTotal = (percentageTotal !== null && percentageTotal !== void 0 ? percentageTotal : 0) + rateLineTotal;
            }
        }
    }
    return {
        dollar: dollarTotal,
        percentage: percentageTotal,
    };
}
exports.periodRateLineTotal = periodRateLineTotal;
